const config = {
      apiKey: "AIzaSyCbckvwKOcIQhJb5USSWQni8lC_icI6z10",
      authDomain: "next-up-website.firebaseapp.com",
      databaseURL: "https://next-up-website.firebaseio.com",
      projectId: "next-up-website",
      storageBucket: "next-up-website.appspot.com",
      messagingSenderId: "467495938715",
      appId: "1:467495938715:web:7f5d4ad51a64dbc8fa343e",
      measurementId: "G-WX30HRKXP3"
}

export default config;
