import { Header, Sitemap, Blob, NCW, NCE, Certi, CARAnimation } from './Components';
import React, { useEffect, useRef } from 'react';
import './Components.css'
import KUTE from 'kute.js'
import { Parallax } from 'react-parallax';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function Main() {
	const blob1Ref = useRef(null)
	const blob2Ref = useRef(null)
	useEffect(() => {
		blob1Ref.current && blob2Ref.current && KUTE.fromTo(
			blob1Ref.current,
			{ path: blob1Ref.current },
			{ path: blob2Ref.current },
			{ repeat: 999, duration: 2000, yoyo: true }
		).start();
	}, [blob1Ref, blob2Ref])

	return (
		<div>
			<Header />

			<Parallax bgImage={require('./images/ml/bg.png')} style={{ height: '100%' }} strength={500}>
				<img src={require('./images/ml/logo.png')} alt='Fundamental ML' height={700} style={{ margin: '50px 0 0 60px', float: 'left' }} />
			</Parallax>

			{/* <CARAnimation /> */}

			<Carousel showArrows={true} showStatus={false} autoPlay={true} infiniteLoop={true} interval={4000}>
				{/* <Blob title={<Certi />} color='blue' /> */}
				<Blob title={<NCW />} color='purple' />
				<Blob title={<NCE />} color='orange' />
			</Carousel>

			<Sitemap />

		</div>
	);
}

export default Main;