import React, { Fragment, useEffect, useState } from 'react';
import { PDFViewer, Page, Text, Image, View, Document, G, Path, StyleSheet, Font, Svg, Polygon, Tspan } from '@react-pdf/renderer';
import './Components.css'
import QRCode from "qrcode.react";
import moment from 'moment';

Font.register({ family: 'OpenSans-BoldItalic', src: `./OpenSans-BoldItalic.ttf` });
Font.register({ family: 'OpenSans-Bold', src: `./OpenSans-Bold.ttf` });
Font.register({ family: 'OpenSans-SemiBold', src: `./OpenSans-SemiBold.ttf` });
Font.register({ family: 'OpenSans-Regular', src: `./OpenSans-Regular.ttf` });

const Letterhead = () => (
    <Svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 595.3 1041.9" style={{ backgroundColor: '#FFF', position: 'absolute', top: 0, left: 0 }}>
        <G id="BG" style={{ opacity: 0.1 }}>
            <G id="Layer_3_2_">
                <Path style={{ fill: '#000' }} d="M550.3,176.5c-164.5,0-297.9,133.4-297.9,297.9s133.4,297.9,297.9,297.9s297.9-133.4,297.9-297.9l0,0
			C848.2,309.9,714.8,176.5,550.3,176.5z M337.9,585.1c-2.4-6.9-1-14.6,3.7-20.2l191.2-250.4c3.1-4.2,7.7-7,12.8-8.1v150.8
			L337.9,585.1z M741.5,600.3c0,0-143.4-46.5-191.2-46.5c-47.8,0-191.2,46.5-191.2,46.5c-6.1,0-11.9-2.4-16.2-6.7l207.3-127.9
			l207.3,127.9C753.4,597.9,747.5,600.3,741.5,600.3z M555,457.2V306.4c5.1,1.1,9.6,3.9,12.8,8.1L759,564.9
			c4.7,5.6,6.1,13.3,3.7,20.2L555,457.2z"/>
            </G>
        </G>
        <G id="Footer">
            <Path style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#000000' }} d="M0,807.6h595.2v34.3H0V807.6z" />
            <Path style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FFFFFF' }} d="M439.2,805h22.4l15.9,37.5H455L439.2,805z" />
            <Path style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FFFFFF' }} d="M521.3,805h22.4l15.9,37.5h-22.4L521.3,805z" />
            <Path style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FFFFFF' }} d="M493.9,805h22.4l15.9,37.5h-22.4L493.9,805z" />
            <Path style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FFFFFF' }} d="M466.5,805h22.4l15.9,37.5h-22.4L466.5,805z" />
        </G>
        <G id="Header">
            <Path style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#000000' }} d="M24,114h295v2.2H24V114z" />
            <Path id="Head" style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#000000' }} d="M0-2.7h595.2v12H0V-2.7z" />
            <G id="Title">
                <G id="Layer_2_2_">
                    <Path style={{ fill: '#000' }} d="M117,44.6h7.9l24.4,36.4h0.1V44.6h6v44.2h-7.6l-24.6-36.3H123v36.3h-6V44.6z" />
                    <Path style={{ fill: '#000' }} d="M179.4,81.5l-0.3,0.2c-0.5,0.4-1.1,0.7-1.7,1c-1.2,0.5-2.5,0.7-3.7,0.7c-1.3,0-2.5-0.2-3.7-0.7c-2.2-0.9-3.9-2.7-4.6-4.9
				c-0.2-0.4-0.3-0.8-0.4-1.2h11v-5.6h-11c0.1-0.3,0.2-0.6,0.3-0.9c0.4-1.1,1-2.1,1.8-3c0.8-0.8,1.7-1.5,2.8-2
				c1.2-0.5,2.4-0.7,3.7-0.7c1.3,0,2.6,0.2,3.7,0.7c0.6,0.3,1.1,0.6,1.6,0.9l0.3,0.2l3.2-4.7l-0.2-0.2c-0.8-0.5-1.7-1-2.6-1.4
				c-3.8-1.6-8.1-1.6-11.9,0c-3.6,1.5-6.5,4.4-8.1,8c-1.6,3.8-1.6,8,0,11.7c0.8,1.8,1.9,3.4,3.2,4.8c1.4,1.4,3,2.4,4.8,3.2
				c3.8,1.6,8.1,1.6,11.9,0c0.9-0.4,1.8-0.9,2.7-1.4l0.2-0.2L179.4,81.5z"/>
                    <Path style={{ fill: '#000' }} d="M196.1,72.8l-10.1-14h7.3l7.2,10.2l7-10.2h6.8l-9.7,14l11.6,16h-7.3l-8.7-12.2l-8.7,12.2h-6.9L196.1,72.8z" />
                    <Polygon style={{ fill: '#000' }} points="241.1,58.9 217.4,58.9 217.4,64.6 226.4,64.6 226.4,88.8 232.1,88.8 232.1,64.6 241.1,64.6 			" />
                    <Path style={{ fill: '#000' }} d="M262.6,44.7v25.9c0,1.4,0.2,2.9,0.5,4.3c0.4,1.5,1,2.8,1.9,4.1c0.9,1.3,2.1,2.3,3.4,3.1c1.4,0.8,3.2,1.2,5.3,1.2
				c1.8,0.1,3.6-0.3,5.2-1.2c1.4-0.7,2.5-1.8,3.4-3.1c0.9-1.2,1.5-2.6,1.9-4.1c0.4-1.4,0.5-2.8,0.5-4.3V44.7h6v26.8
				c0,2.4-0.4,4.8-1.3,7c-0.8,2-2,3.9-3.6,5.5c-1.6,1.6-3.4,2.8-5.5,3.6c-4.3,1.7-9.1,1.7-13.5,0c-2-0.8-3.9-2-5.4-3.6
				c-1.6-1.6-2.8-3.4-3.6-5.5c-0.9-2.2-1.4-4.6-1.3-7V44.7H262.6z"/>
                    <Path style={{ fill: '#000' }} d="M294.7,88.8V58.9h10.7c1.5,0,2.9,0.1,4.3,0.4c1.2,0.2,2.4,0.7,3.4,1.4c1,0.7,1.8,1.6,2.3,2.6c0.6,1.1,0.9,2.5,0.9,4.2
				c0,1.4-0.2,2.9-0.8,4.2c-0.5,1-1.3,2-2.2,2.7c-1,0.7-2.2,1.2-3.4,1.4c-1.4,0.3-2.9,0.5-4.3,0.4h-5.3v12.5H294.7z M300.3,71.6h4.9
				c0.6,0,1.2-0.1,1.8-0.2c0.6-0.1,1.1-0.3,1.6-0.6c0.5-0.3,0.9-0.7,1.2-1.2c0.3-0.6,0.5-1.3,0.5-2c0-0.8-0.2-1.5-0.6-2.1
				c-0.4-0.5-0.9-0.9-1.5-1.2c-0.6-0.3-1.3-0.5-2-0.5l-2-0.1h-3.9V71.6z"/>
                </G>
                <G id="Layer_3_1_">
                    <Path style={{ fill: '#000' }} d="M61.1,30.6c-19.1,0-34.5,15.5-34.5,34.5S42,99.7,61.1,99.7s34.5-15.5,34.5-34.5S80.1,30.6,61.1,30.6z M36.4,78
				c-0.3-0.8-0.1-1.7,0.4-2.4l22.2-29c0.4-0.5,0.9-0.8,1.5-1v17.5L36.4,78z M83.2,79.8c0,0-16.6-5.4-22.2-5.4s-22.2,5.4-22.2,5.4
				c-0.7,0-1.3-0.3-1.8-0.8l24-14.8l24,14.8C84.6,79.5,83.9,79.8,83.2,79.8z M61.6,63.2V45.7c0.6,0.2,1.1,0.5,1.5,1l22.2,29
				c0.5,0.7,0.7,1.6,0.4,2.4L61.6,63.2z"/>
                </G>
            </G>
        </G>
        <G id="Location">
            <G>
                <Path style={{ fill: '#000' }} d="M43.8,790.5c-6.2-0.1-11.1-5.3-11-11.6c0.1-6.1,5.3-11,11.5-10.8c6.1,0.1,11,5.2,11,11.4C55.2,785.7,50,790.6,43.8,790.5z
			"/>
                <G>
                    <Path style={{ fill: '#FFFFFF' }} d="M44.6,772.9c0.2,0,0.4,0.1,0.6,0.1c1.8,0.3,3.3,1.9,3.6,3.8c0.2,1.3-0.1,2.5-0.8,3.6
				c-1.1,1.6-2.2,3.2-3.4,4.8c-0.2,0.3-0.5,0.3-0.7,0c-1.2-1.6-2.3-3.2-3.4-4.9c-1.9-2.8-0.4-6.6,3-7.3c0.1,0,0.3,0,0.4-0.1
				C44,772.9,44.3,772.9,44.6,772.9z"/>
                    <Path style={{ fill: '#000' }} d="M45.8,777.6c0,0.9-0.7,1.6-1.6,1.5c-0.9,0-1.5-0.7-1.5-1.5c0-0.9,0.7-1.5,1.6-1.5C45.1,776,45.8,776.7,45.8,777.6z" />
                </G>
            </G>
            <Text transform="matrix(1 0 0 1 63.75 772.926)"><Tspan x="0" y="0" style={{ fontFamily: 'OpenSans-SemiBold', fontSize: 7 }}>No. 27/1, 4th Cross, 3rd Main,</Tspan><Tspan x="0" y="8.4" style={{ fontFamily: 'OpenSans-SemiBold', fontSize: 7 }}>Venkateshwara Layout, SG Palya,</Tspan><Tspan x="0" y="16.8" style={{ fontFamily: 'OpenSans-SemiBold', fontSize: 7 }}>Bangalore - 560029  </Tspan></Text>
        </G>
        <G id="Email">
            <G>
                <Path style={{ fill: '#000' }} d="M252.5,790.5c-6.2-0.1-11.1-5.3-11-11.6c0.1-6.1,5.3-11,11.5-10.8c6.1,0.1,11,5.2,11,11.4
			C263.9,785.7,258.7,790.6,252.5,790.5z"/>
                <G>
                    <Path style={{ fill: '#FFFFFF' }} d="M258,783.6c-3.5,0-7,0-10.5,0c0,0,0,0,0.1-0.1c1.3-1.3,2.7-2.7,4-4.1c0.1-0.1,0.1-0.1,0.2,0
				c0.3,0.3,0.7,0.6,1,0.9c0,0,0.1,0,0.1,0c0.3-0.3,0.7-0.6,1-0.9c0.1,0,0.1,0,0.1,0c1.3,1.3,2.5,2.6,3.8,4
				C257.8,783.5,257.9,783.5,258,783.6z"/>
                    <Path style={{ fill: '#FFFFFF' }} d="M247.4,775c3.6,0,7.1,0,10.7,0c-0.1,0.1-0.3,0.2-0.4,0.3c-1.6,1.3-3.3,2.6-4.9,3.9c0,0-0.1,0-0.1,0
				C251,777.8,249.2,776.4,247.4,775C247.4,775.1,247.4,775.1,247.4,775z"/>
                    <Path style={{ fill: '#FFFFFF' }} d="M247,775.7c0.2,0.1,0.4,0.3,0.5,0.4c1.1,0.9,2.2,1.8,3.4,2.8c0,0,0.1,0,0,0.1c-1.3,1.3-2.6,2.7-3.9,4
				c0,0,0,0,0,0.1c0-0.1,0-0.2,0-0.3c0-1.3,0-2.6,0-3.9c0-1,0-2.1,0-3.1C247,775.8,247,775.7,247,775.7z"/>
                    <Path style={{ fill: '#FFFFFF' }} d="M258.5,775.7c0,2.4,0,4.8,0,7.1c0,0,0,0.1,0,0.1c-0.1-0.1-0.3-0.3-0.4-0.4c-1.2-1.2-2.3-2.4-3.5-3.6
				c0,0-0.1-0.1,0-0.1c1.3-1,2.5-2.1,3.8-3.1C258.4,775.7,258.4,775.7,258.5,775.7z"/>
                </G>
            </G>
            <Text transform="matrix(1 0 0 1 271.3602 777.1259)"><Tspan x="0" y="0" style={{ fontFamily: 'OpenSans-SemiBold', fontSize: 7 }}>PublicRelations@NextUp.co.in</Tspan><Tspan x="0" y="8.4" style={{ fontFamily: 'OpenSans-SemiBold', fontSize: 7 }}>https://NextUp.co.in</Tspan></Text>
        </G>
        <G id="Legal">
            <G>
                <Path style={{ fill: '#000' }} d="M452.9,790.5c-6.2-0.1-11.1-5.3-11-11.6c0.1-6.1,5.3-11,11.5-10.8c6.1,0.1,11,5.2,11,11.4
			C464.3,785.7,459.2,790.6,452.9,790.5z"/>
                <G>
                    <Path style={{ fill: '#FFFFFF' }} d="M458.4,776.2c-3.5,0-6.9,0-10.4,0c0-0.3,0-0.5,0-0.7c0,0,0-0.1,0.1-0.1c0.5-0.3,1-0.5,1.6-0.8
				c0.5-0.2,0.9-0.4,1.4-0.7c0.7-0.3,1.3-0.7,2.1-1c0,0,0.1,0,0.1,0c0.7,0.3,1.3,0.6,2,1c0.6,0.3,1.2,0.6,1.8,0.9
				c0.4,0.2,0.9,0.4,1.3,0.6c0,0,0.1,0,0.1,0.1C458.4,775.7,458.4,775.9,458.4,776.2z"/>
                    <Path style={{ fill: '#FFFFFF' }} d="M460.4,783.9c0,0.3,0,0.5,0,0.8c-4.8,0-9.6,0-14.4,0c0-0.3,0-0.5,0-0.8C450.8,783.9,455.6,783.9,460.4,783.9
				z"/>
                    <Path style={{ fill: '#FFFFFF' }} d="M459.4,782.6c0,0.3,0,0.5,0,0.8c-4.1,0-8.3,0-12.4,0c0-0.3,0-0.5,0-0.8C451.1,782.6,455.3,782.6,459.4,782.6
				z"/>
                    <Path style={{ fill: '#FFFFFF' }} d="M458.4,781.4c0,0.3,0,0.5,0,0.8c-3.5,0-6.9,0-10.4,0c0-0.3,0-0.5,0-0.8C451.4,781.4,454.9,781.4,458.4,781.4
				z"/>
                    <Path style={{ fill: '#FFFFFF' }} d="M451.2,776.9c0.4,0,0.9,0,1.3,0c0,1.3,0,2.7,0,4c-0.4,0-0.9,0-1.3,0C451.2,779.6,451.2,778.2,451.2,776.9z"
                    />
                    <Path style={{ fill: '#FFFFFF' }} d="M455.2,780.9c0,0-0.1,0-0.1,0c-0.4,0-0.7,0-1.1,0c-0.1,0-0.1,0-0.1-0.1c0-0.9,0-1.8,0-2.7c0-0.4,0-0.8,0-1.2
				c0,0,0-0.1,0-0.1c0.4,0,0.9,0,1.3,0C455.2,778.2,455.2,779.6,455.2,780.9z"/>
                    <Path style={{ fill: '#FFFFFF' }} d="M449.9,780.9c-0.4,0-0.9,0-1.3,0c0-1.3,0-2.7,0-4c0.4,0,0.9,0,1.3,0C449.9,778.2,449.9,779.6,449.9,780.9z"
                    />
                    <Path style={{ fill: '#FFFFFF' }} d="M457.8,776.9c0,1.3,0,2.7,0,4c-0.4,0-0.9,0-1.3,0c0-1.3,0-2.7,0-4C456.9,776.9,457.3,776.9,457.8,776.9z" />
                </G>
            </G>
            <Text transform="matrix(1 0 0 1 472.422 777.1264)"><Tspan x="0" y="0" style={{ fontFamily: 'OpenSans-SemiBold', fontSize: 7 }}>M/s. AUTRE LEARNING LLP</Tspan><Tspan x="0" y="8.4" style={{ fontFamily: 'OpenSans-SemiBold', fontSize: 7 }}>GSTIN: 29ABQFA4808H1Z4</Tspan></Text>
        </G>
    </Svg>
)

const AcceptanceLetterBody = (props) => (
    <Text style={{ fontFamily: 'OpenSans-Regular', fontSize: '11px', fontWeight: 'light', lineHeight: '4%' }}>
        To,{'\n'}
        {props.name}{'\n'}
        {props.college},{'\n'}Bengaluru{'\n'}{'\n'}
        August 27, 2021{'\n'}{'\n'}
        Dear {props.gender == 'male' ? `Mr. ` : `Ms. `}{props.name},{'\n'}{'\n'}
        <Text style={{ fontFamily: 'OpenSans-Bold', textDecoration: 'underline', textAlign: 'center', fontSize: '12px' }}>
            Sub: Regarding your internship in our Company’s Development Team{'\n'}{'\n'}
        </Text>
        We would like to confirm that your application for an internship for the position of
        <Text style={{ fontFamily: 'OpenSans-Bold' }}> Machine Learning Intern </Text>in
        <Text style={{ fontFamily: 'OpenSans-Bold' }}> Development Team </Text>
        has been accepted. The terms of internship while working with Next
        Up are as follows:{'\n'}{'\n'}
        <Text style={{ textIndent: 30 }}>
            1. Duration of internship will be from
            <Text style={{ fontFamily: 'OpenSans-Bold' }}>August 27, 2021 </Text>to
            <Text style={{ fontFamily: 'OpenSans-Bold' }}> September 24, 2021.</Text>{'\n'}
            2. You will be designated as “Trainee” as per Company Policy.{'\n'}
            3. You will not been titled or any other benefits from the company during this tenure.{'\n'}
            4. During internship, you are expected to abide by the Code of Conduct prescribed by the Company for all the employees.{'\n'}{'\n'}
        </Text>
        Your attendance of the sessions indicates your acceptance to these terms. You will receive further details via email in the days leading up to your Internship, in the meantime, feel free to explore our products and services, and don’t forget to celebrate!{'\n'}{'\n'}
        Additionally, feel free to reach out to us for any queries. Wishing you good luck for your future endeavours.{'\n'}{'\n'}Sincerely,
    </Text>
)

const NishankSignatory = () => (
    <Fragment>
        <Image src={require('./images/doc/Nsign.png')} style={{ height: 50, width: 150, position: 'absolute', top: '99%', left: -10 }} />
        <Image src={require('./images/doc/logoSeal.png')} style={{ height: 78, width: 78, position: 'absolute', top: '96%', left: 97 }} />

        <Text style={{ fontFamily: 'OpenSans-Bold', fontSize: '11px', fontWeight: 'light', lineHeight: '4%', top: 70 }}>
            Nishank Swamy D N{'\n'}
            <Text style={{ fontFamily: 'OpenSans-Regular' }}> MD, Institutional Operations | </Text>
            <Text style={{ fontFamily: 'OpenSans-BoldItalic' }}> Next Up </Text>
        </Text>
    </Fragment>
)


function RenderDocuments(props) {
    const user = props.location.state.user

    const getCanvasURIPromise = () => {
        return new Promise((resolve, reject) => {
            const canvas = document.querySelector('.HpQrcode > canvas');
            if (canvas) resolve(Buffer.from(canvas.toDataURL().split('base64,')[1], 'base64'))
            else reject('')
        })
    }

    return (
        <Fragment>
            <div className="HpQrcode" style={{ display: 'none' }}>
                <QRCode value={`1.0/${user.documents[0].id}/${moment(user.documents[0].date, "YYYY-MM-DD").format("YYYYMMDD")}/${user.documents[0].url.substring(10, 18)}`} />
            </div>
            <PDFViewer style={{ height: window.innerHeight, width: '100%' }}>
                <Document>
                    <Page size="A4" className='page'>
                        <View>
                            <Letterhead />
                        </View>
                        <View style={{ position: 'absolute', top: 138, left: 0, marginLeft: 36, marginRight: 36 }} >
                            <View style={{ position: 'absolute', top: -96, right: -5 }}>
                                <Image src={async () => await getCanvasURIPromise()} style={{ height: 46, width: 46 }} />
                            </View>

                            <AcceptanceLetterBody {...user} />
                            <NishankSignatory />
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </Fragment>
    )
};
export default RenderDocuments;