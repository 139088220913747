import {Header} from './Components' ;
import React from 'react';

function Graphic() {
  	return (
		<div><Header /></div>
	);
}

export default Graphic;
