import { Header, RegisterBg, Sitemap } from './Components';
import React, { useState, useEffect, useContext } from 'react';
import { useForm } from "react-hook-form";
import { Redirect } from 'react-router';
import Form from 'react-bootstrap/Form';
import DB from './DB';
import './Register.css';
import moment from 'moment';

function Register(props) {
	const db = useContext(DB)

	const [selectedJob, setSelectedJob] = useState('student');
	const [selectedGender, setSelectedGender] = useState('male');
	const { register, handleSubmit, setValue } = useForm({ defaultValues: { job: 'student', gender: 'male' } })
	const [redirect] = useState();
	const [eventInfo, setEventInfo] = useState();

	var selectedCourse = props.location.state

	function dec2hex(dec) {
		return ('0' + dec.toString(16)).substr(-2)
	}

	function generateId(len) {
		var arr = new Uint8Array((len || 40) / 2)
		window.crypto.getRandomValues(arr)
		return Array.from(arr, dec2hex).join('')
	}

	// function getUrlVars() {
	// 	var vars = {};
	// 	var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
	// 		vars[key] = value || '';
	// 	});
	// 	return vars;
	// }

	useEffect(() => {
		db.getDatabase(`/courses/${selectedCourse.course}`, data => {
			setEventInfo(data.events[selectedCourse.id])
		})
	}, [db,selectedCourse])

	const submitHandler = async (d) => {
		//show loading

		// Object.keys(data).forEach(i => {
		// 	// if (!data[i].checkValidity()) {
		// 	// 	setisValid(false)
		// 	// 	console.log("data.checkValidity() === false");
		// 	// 	d.preventDefault();
		// 	// 	d.stopPropagation();
		// 	// }
		// })

		let txnid = await generateId(12), event = eventInfo.code + String(eventInfo.batch).padStart(2, '0')
		db.newRegistration(txnid, d, event, eventInfo.amount, () => {
			window.location.href = `checkout.html?name=${d.name}&email=${d.email}&phone=${d.mobile}&txn=${txnid}&ev=${event}&a=${eventInfo.amount}`
		})
	};

	if (redirect) return <Redirect push to={{ pathname: redirect, state: selectedCourse }} />

	return (
		<div >
			<Header />
			<RegisterBg />

			<div className="registerContainer">
				<img src={require("./images/" + selectedCourse.course + "/logo.png")} className='fadeBottom' id='logo' alt="Creating Augmented Realities" />
				{
					eventInfo && <div id="eventList" className="box shadow" >
						<h3 className="date">{selectedCourse.title}</h3>
						<b className="fas ">{"\uf073"}</b><span>&nbsp; {moment(eventInfo.date.start, "YYYY-MM-DD").format('MMM D')} - {moment(eventInfo.date.end, "YYYY-MM-DD").format('MMM D, YYYY')}</span><br />
						<b className="fas ">{"\uf017"}</b><span>&nbsp;  {eventInfo.duration} hrs/day, Mon - Sun, 29 days</span><br />
						<b className="fas ">{"\uf3c5"}</b><span>&nbsp; {eventInfo.isOnline ? "Online Live Session" : "Offline Session"}</span><br />
						<b className="fas ">{"\uf406"}</b><span>&nbsp;  {eventInfo.entry}</span><br />
						<b className="fas ">{"\uf0c0"}</b><span>&nbsp; Registration Limit: {eventInfo.seats}</span><br /><hr />
						<div className='amount' style={{ textAlign: 'left' }}>Subtotal</div>
						<div className='amount' style={{ textAlign: 'right' }}>₹ {(eventInfo.amount / 1.23).toFixed(2)}</div><br />
						<div className='amount' style={{ textAlign: 'left' }}>GST</div>
						<div className='amount' style={{ textAlign: 'right' }}>₹ {(eventInfo.amount * 0.18).toFixed(2)}</div><br />
						<div className='amount' style={{ textAlign: 'left' }}>Processing Fee</div>
						<div className='amount' style={{ textAlign: 'right' }}>₹ {(eventInfo.amount * 0.05).toFixed(2)}</div><br />
						<b className='amount' style={{ textAlign: 'left' }}>Total</b>
						<b className='amount' style={{ textAlign: 'right' }}>₹ {eventInfo.amount.toFixed(2)}</b><br />
					</div>
				}
				<br />
				<center>
					<h3>REGISTER</h3>
					<p>Fill out this form to attend the Next Up&reg; <b>Fundamental Machine Learning&trade;</b> program. <a href="/ml">Learn More</a>.</p>
					<br />
				</center>

				<Form className='registerForm' onSubmit={handleSubmit(submitHandler)}>
					<Form.Group>
						<Form.Label><b className='detail'>FULL NAME</b></Form.Label>
						<span className='dot'></span><br />
						<div style={{ display: 'inline-flex', width: '100%' }}>
							<Form.Control type="text" placeholder="TO BE PRINTED ON CERTIFICATE" name='name' className="textfield" ref={register({ required: true })} />
						</div>
					</Form.Group>

					<Form.Group>
						<Form.Label><b style={{ fontSize: 14 }}>EMAIL</b></Form.Label>
						<span className='dot'></span>
						<div style={{ display: 'inline-flex', width: '100%' }}>
							<Form.Control type="email" placeholder="jon@snow.com" name='email' className="textfield" ref={register({ required: true, pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i } })} />
						</div>
					</Form.Group>

					<Form.Group>
						<Form.Label><b style={{ fontSize: 14 }}>WHATSAPP NUMBER</b></Form.Label>
						<span className='dot'></span>
						<div style={{ display: 'inline-flex', width: '100%' }}>
							<Form.Control type="number" placeholder="9099123456" name='mobile' className="textfield" ref={register({ required: true })} />
						</div>
					</Form.Group>

					<Form.Group>
						<Form.Label><b style={{ fontSize: 14 }}>OCCUPATION</b></Form.Label>
						<span className='dot'></span>
						<div id="job" className="selectContainer">
							<div value="student" className={`sc3 selectItem ${selectedJob === 'student' ? 'selectItemTrue' : ''}`} onClick={() => { setSelectedJob('student'); setValue('job', 'student'); }}>
								<b className="fas" style={{ fontSize: '26px' }}>{"\uf19d"}</b><br />
								Student
							</div>
							<div value="faculty" className={`sc3 selectItem ${selectedJob === 'faculty' ? 'selectItemTrue' : ''}`} onClick={() => { setSelectedJob('faculty'); setValue('job', 'faculty'); }}>
								<b className="fas" style={{ fontSize: '26px' }}>{"\uf0b1"}</b><br />
								Faculty
							</div>
							<div value="other" className={`sc3 selectItem ${selectedJob === 'other' ? 'selectItemTrue' : ''}`} onClick={() => { setSelectedJob('other'); setValue('job', 'other'); }}>
								<b className="fas" style={{ fontSize: '26px' }}>{"\uf013"}</b><br />
								Other
							</div>
						</div>
						<Form.Control type="text" name='job' ref={register({ required: false })} style={{ display: 'none' }} />
					</Form.Group>

					<Form.Group>
						<Form.Label><b style={{ fontSize: 14 }}>GENDER</b></Form.Label>
						<span className='dot'></span>
						<div id="gender" className="selectContainer">
							<div value="male" className={`sc3 selectItem ${selectedGender === 'male' ? 'selectItemTrue' : ''}`} onClick={() => { setSelectedGender('male'); setValue('gender', 'male'); }}>
								<b className="fas" style={{ fontSize: '26px' }}>{"\uf183"}</b><br />
								Male
							</div>
							<div value="female" className={`sc3 selectItem ${selectedGender === 'female' ? 'selectItemTrue' : ''}`} onClick={() => { setSelectedGender('female'); setValue('gender', 'female'); }}>
								<b className="fas" style={{ fontSize: '26px' }}>{"\uf182"}</b><br />
								Female
							</div>
							<div value="other" className={`sc3 selectItem ${selectedGender === 'other' ? 'selectItemTrue' : ''}`} onClick={() => { setSelectedGender('other'); setValue('gender', 'other'); }}>
								<b className="fas" style={{ fontSize: '26px' }}>{"\uf224"}</b><br />
								Other
							</div>
						</div>
						<Form.Control type="text" name='gender' ref={register({ required: false })} style={{ display: 'none' }} />
					</Form.Group>
					<hr />
					{
						(selectedJob === 'faculty' || selectedJob === 'student') && <div id='uni'>
							<Form.Group>
								<Form.Label><b style={{ fontSize: 14 }}>INSTITUTION NAME</b></Form.Label>
								<span className='dot'></span><br />
								<div style={{ display: 'inline-flex', width: '100%' }}>
									<Form.Control type="text" placeholder="STANFORD UNIVERSITY" name='college' className="textfield" ref={register({ required: true })} />
								</div>
							</Form.Group>
							<Form.Group>
								<Form.Label><b style={{ fontSize: 14 }}>PROGRAM DEPARTMENT</b></Form.Label>
								<span className='dot'></span>
								<div style={{ display: 'inline-flex', width: '100%' }}>
									<Form.Control type="text" placeholder="CS" name='department' className="textfield" ref={register({ required: true })} />
								</div>
							</Form.Group>
							<hr />
						</div>
					}
					{
						(selectedJob === 'student') && <div id='stuDetails'>
							<Form.Group style={{ display: 'inline-flex', width: '100%' }}>
								<Form.Group style={{ display: 'inline-block', width: '47%' }}>
									<Form.Label><b style={{ fontSize: 14 }}>SEMESTER</b></Form.Label>
									<span className='dot'></span><br />
									<div style={{ display: 'inline-flex', width: '100%' }}>
										<Form.Control type="number" placeholder="1" name='sem' className="textfield" ref={register({ required: true })} />
									</div>
								</Form.Group>
								<Form.Group style={{ display: 'inline-block', width: '47%', marginLeft: '5%' }}>
									<Form.Label><b style={{ fontSize: 14 }}>CLASS / SECTION</b></Form.Label>
									<div style={{ display: 'inline-flex', width: '100%' }}>
										<Form.Control type="text" placeholder="A" name='class' className="textfield" ref={register()} />
									</div>
								</Form.Group>
							</Form.Group>
							<hr />
						</div>
					}

					<div className='terms'>Terms and Conditions</div>
					<ol>
						<li>Any amount paid towards the registration is non-refundable and non-transferrable.</li>
						<li>The resource person(s) are not liable in event of inability of a participant to possess the necessary resources (mentioned below).</li>
						<li>Certificate will only be issued upon attendance of all days of the program.</li>
						<li>The resource person(s) and conference OEM reserve the right to admission.</li>
					</ol>
					<hr />

					<div className='terms'>Acknowledgements<span className='dot'></span></div>
					<label className="checkboxContainer"><span>I agree to the Terms and Conditions</span>
						<input type="checkbox" name="ack1" required />
						<span className="checkmark"></span>
					</label>

					<label className="checkboxContainer"><span>I have a working laptop and internet connection to participate in the program</span>
						<input type="checkbox" name="ack2" required />
						<span className="checkmark" ></span>
					</label>

					<button id="registerbtn" type="submit" className="registerbtn">Proceed {eventInfo && `to Pay ₹ ${eventInfo.amount.toFixed(2)}`}</button>
				</Form>
			</div>
			<Sitemap />
		</div>
	);
}

export default Register;
