import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faLinkedinIn, } from '@fortawesome/free-brands-svg-icons';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal'
import Nav from 'react-bootstrap/Nav';
import { useForm } from "react-hook-form";
import ReactPlayer from 'react-player'
import Form from 'react-bootstrap/Form';
import React, { useState, useEffect, useCallback, Fragment, useRef, Suspense } from 'react';
import { useDropzone } from 'react-dropzone'
import moment from 'moment';
import './Components.css';
import './Register.css';
import KUTE from 'kute.js'
import { Canvas, useLoader, useFrame } from '@react-three/fiber'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import QRCode from "qrcode.react";
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';


export const Header = props => {
	const [useDarkMode, setDarkMode] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches)

	useEffect(() => {
		window.matchMedia('(prefers-color-scheme: dark)').addListener(e => setDarkMode(e.matches));
	}, [])

	return (
		<Navbar className='TopNav' collapseOnSelect expand="lg" bg="navbar" variant={useDarkMode ? 'dark' : 'light'} fixed="top" >
			<Navbar.Brand href="/">&nbsp;&nbsp;
				<img alt="" src={require(`./images/main/logo${useDarkMode ? '-white' : ''}.png`)} height="34" className="d-inline-block align-top" />&nbsp;&nbsp;
			</Navbar.Brand>

			<Navbar.Toggle aria-controls="responsive-navbar-nav" />

			<Navbar.Collapse id="responsive-navbar-nav" >
				<Nav className="mr-auto" >
					<NavDropdown title="Programs" id="collasible-nav-dropdown"  menuVariant={useDarkMode ? 'dark' : 'light'}>&nbsp;
						<NavDropdown.Item href="/ar">Creating AR</NavDropdown.Item>&nbsp;&nbsp;
						<NavDropdown.Item href="/ml">Fundamental ML</NavDropdown.Item>&nbsp;&nbsp;
						<NavDropdown.Item href="/web-design">Useful Web Design</NavDropdown.Item>&nbsp;&nbsp;
						<NavDropdown.Item href="/ui">Reasonable UI</NavDropdown.Item>&nbsp;&nbsp;
						<NavDropdown.Item href="/graphic-design">Employable Graphic Design</NavDropdown.Item>&nbsp;&nbsp;
						<NavDropdown.Item href="/digital-marketing">Digital Marketing</NavDropdown.Item>&nbsp;&nbsp;
						<NavDropdown.Item href="/presenting">Art of Presenting</NavDropdown.Item>&nbsp;
					</NavDropdown>&nbsp;&nbsp;
					<Nav.Link href="/about">About</Nav.Link>&nbsp;&nbsp;
					<Nav.Link href="/gallery">Gallery</Nav.Link>&nbsp;&nbsp;
					<Nav.Link href="/contact">Contact</Nav.Link>&nbsp;&nbsp;
					<Nav.Link href="/login">Login</Nav.Link>&nbsp;&nbsp;
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	)
}

export const BarCodeModal = props => {
	const { register, handleSubmit, getValues, setValue } = useForm();
	const [qrCodeValue, setQrCodeValue] = useState()
	const [qrCodeNextValue, setQrCodeNextValue] = useState()
	const downloadRef = useRef(null)

	const downloadFile = () => {
		if (downloadRef.current) {
			const canvas = document.querySelector('.HpQrcode > canvas');
			window.location.href = canvas.toDataURL()
			var string = canvas.toDataURL()
			const downloadLink = document.createElement("a");
			downloadLink.download = "qrcode";
			downloadLink.href = `${string}`;
			downloadLink.click();
		}
	}

	const makeid = (length) => {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	const submitHandler = (d) => {
		let pass1 = makeid(8)
		let pass2 = makeid(8)
		let str1 = `1.0/${d.dept}-0000${d.id}/${d.year}${d.month.padStart(2, 0)}${d.date.padStart(2, 0)}/${pass1}`
		let str2 = `1.0/${d.dept}-0000${d.id}/${d.year}${d.month.padStart(2, 0)}${d.date.padStart(2, 0)}/${pass2}`
		setQrCodeValue(str1);
		setQrCodeNextValue(str2)
	}

	const onChange = (e) => {
		var d = getValues('dept')
		switch (d) {
			case 'HR':
				setValue('id', props.documents.HR.length + 1); console.log(props.documents.HR.length + 1); break;
			case 'BR':
				setValue('id', props.documents.BR.length + 1); console.log(props.documents.BR.length + 1); break;
			case 'CR':
				setValue('id', props.documents.CR.length + 1); break;
		}
	};

	return (
		<Modal show={props.show} size='lg' onHide={() => { props.set(false) }}>
			<Modal.Header style={{ padding: '20px 40px 10px 40px' }}>
				<Modal.Title>Barcode Generator</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ paddingLeft: 40, paddingRight: 40 }}>
				{props.documents && <form onSubmit={handleSubmit(submitHandler)} className='barCodeFieldStyle'>
					<select name="dept" ref={register} style={{ width: '22%' }} onChange={onChange}>
						<option value="BR">BR</option>
						<option value="HR">HR</option>
						<option value="CR">CR</option>
					</select>
					<input type="number" name="id" ref={register} style={{ width: '22%', marginLeft: 8 }} />
					<br />
					<input type="number" defaultValue={moment().format('D')} name="date" ref={register} />
					<input type="number" defaultValue={moment().format('M')} name="month" ref={register} />
					<input type="number" defaultValue={moment().format('YYYY')} name="year" ref={register} />
					<br />
					<input type="text" defaultValue="Gov College" name="purpose" ref={register} style={{ width: '55%', padding: '10px 15px' }} />
					<br />
					<button type='submit' className='generate' style={{ width: '47%' }}>Generate</button>
					<br />
				</form>
				}
				{qrCodeValue && <Fragment>
					<br />

					<div className="HpQrcode">
						<QRCode value={qrCodeValue} className='qrcode' size={105} /><br />
					</div>
					<button ref={downloadRef} className='generate' onClick={() => { onChange(); downloadFile(); props.db.setQRCode(getValues("dept"), `${getValues("year")}${getValues("month").padStart(2, 0)}${getValues("date").padStart(2, 0)}`, getValues("id"), qrCodeValue.slice(qrCodeValue.length - 8), getValues("purpose")) }}>Download</button>
					<button ref={downloadRef} className='generate' onClick={() => { onChange(); downloadFile(); props.db.setQRCode(getValues("dept"), `${getValues("year")}${getValues("month").padStart(2, 0)}${getValues("date").padStart(2, 0)}`, parseInt(getValues("id")) + 1, qrCodeNextValue.slice(qrCodeValue.length - 8), getValues("purpose")) }}>Download Next</button>
				</Fragment>
				}

				<br />
			</Modal.Body>
		</Modal>
	)
}

export const NDAModal = props => {
	const urlVars = {
		date: '9-9-2021', Do: '9th', YYYY: '2021', MMMM: 'September',
		name: props.user.name, phone: props.user.phone
	}

	const { register, handleSubmit, formState } = useForm({
		mode: "onChange"
	})

	const submitHandler = (d) => {
		props.acceptNDA(props.user, () => {
			props.set(false)
		})
	}

	return (
		<Modal show={props.show} size='xl' onHide={() => { props.set(false) }}>
			<Modal.Header style={{ padding: '20px 40px 10px 40px' }}>
				<Modal.Title>Non-Disclosure Agreement</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ paddingLeft: 40, paddingRight: 40 }}>
				<iframe title="NDA" src={`/NDAOnline.html?${Object.keys(urlVars).map(k => `${k}=${urlVars[k]}`).join('&')}`}
					style={{ border: 0 }} width='100%' height={500}></iframe>
				<form onSubmit={handleSubmit(submitHandler)} >
					<label className="checkboxContainer"><span>I have read, understood and accept the terms of this non-disclosure agreement.</span>
						<input type="checkbox" name="ack1" ref={register({ required: true })} />
						<span className="checkmark"></span>
					</label>
					<button id="registerbtn" type="submit" className="registerbtn" disabled={!formState.isValid} style={{ backgroundColor: (formState.isValid ? 'black' : '#d4d2d2') }}>
						Accept NDA
					</button>
				</form>
			</Modal.Body>
		</Modal>
	)
}

export const DashboardNavBar = props => {
	const [barCodeModal, setBarCodeModal] = useState(false)
	const [showNDAModal, setNDAModal] = useState(false)

	const [useDarkMode, setDarkMode] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches)

	useEffect(() => {
		window.matchMedia('(prefers-color-scheme: dark)').addListener(e => setDarkMode(e.matches));
	}, [])

	return (
		<Fragment>
			<Navbar className='TopNav shadow' collapseOnSelect expand="lg" bg="navbar" variant={useDarkMode ? 'dark' : 'light'} fixed="top" >
				<Navbar.Brand href="/">&nbsp;&nbsp;
					<img alt="" src={require(`./images/main/logo${useDarkMode ? '-white' : ''}.png`)} height="34" className="d-inline-block align-top" />&nbsp;&nbsp;
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="mr-auto" >
						{/* <Nav.Link href="/Agenda_ML04.pdf">Agenda</Nav.Link>&nbsp;&nbsp; */}
						<Nav.Link href="https://chat.whatsapp.com/FO1VlVwOcbq2EbJ2uhDyMK">WhatsApp</Nav.Link>&nbsp;&nbsp;
						{props.showNDA && <Nav.Link onClick={() => setNDAModal(!showNDAModal)}>NDA</Nav.Link>}&nbsp;&nbsp;
						{props.showRefer && <Nav.Link href="old/refer.html">Refer</Nav.Link>}&nbsp;&nbsp;
						{props.barCode && <Nav.Link onClick={() => setBarCodeModal(!barCodeModal)}>Barcode</Nav.Link>}&nbsp;&nbsp;
					</Nav>
					<div className="betaTag">BETA</div>
					<div className="name">{props.user.name} </div>
					<div className="profile-button pressable" alt="" onClick={props.onClick} ><b className="fas" >{"\uf00d"}</b></div>

				</Navbar.Collapse>
			</Navbar>
			{barCodeModal && <BarCodeModal show={barCodeModal} set={setBarCodeModal} documents={props.documents}  {...{ db: props.db }} />}
			{showNDAModal && <NDAModal show={showNDAModal} set={setNDAModal} user={props.user} acceptNDA={props.acceptNDA} />}
		</Fragment>
	)
}

export const CARAnimation = props => {
	return (
		<div frameloop="demand" style={{ height: 700, position: 'relative' }}>
			<Canvas mode="concurrent" frameloop="demand" camera={{ fov: 20, near: 0.1, far: 1800, position: [300, -300, 1300] }}>
				<CARAnimationMesh />
			</Canvas >
			<div className='carAnimationBg' />
			<img src={require('./images/ar/logo.png')} height={620} style={{ position: 'absolute', top: 35, right: 20 }} alt='' />
		</div>
	)
}

export const CARAnimationMesh = props => {
	const myMesh = useRef();
	const [meshScroll, setMeshScroll] = useState(-0.2)

	useFrame(() => {
		myMesh.current.rotation.x = 0.35
		myMesh.current.rotation.y = meshScroll
		myMesh.current.rotation.z = -0.1
	});

	useEffect(() => {
		const onScroll = e => {
			setMeshScroll(((window.innerHeight - e.target.documentElement.scrollTop) / 12000) - 0.2)
		};
		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, [myMesh]);

	const Scene = () => {
		const obj = useLoader(OBJLoader, '/AR3D.obj')
		return <primitive object={obj} />
	};

	return (
		<mesh castShadow receiveShadow ref={myMesh}>
			{/* <ambientLight intensity={2}  /> */}
			<directionalLight color="white" position={[1, 5, 10]} />
			<Suspense fallback={null}>
				<Scene />
			</Suspense>
		</mesh>

	)
}

export const Circle = props => (
	<div className='circle-container' style={props.style} onClick={props.onClick}>
		<div className={props.small ? "circle-sm" : "circle"} style={{ backgroundColor: props.invert ? 'white' : props.color || 'darkgrey' }}>
			<center><b className="fas" style={{ color: !props.invert ? 'white' : props.color }}>{props.icon}</b></center>
		</div>
		<br />
		{props.title ? <p style={props.titleStyle || {}}>{props.title}</p> : null}
	</div>
)

export const TermsModal = props => {
	const [agreeTerms, setAgreeTerms] = useState(JSON.parse(localStorage.getItem('terms')));

	const submitHandler = (d) => {
		localStorage.setItem('terms', true);
		setAgreeTerms(true);
	}
	const { register, handleSubmit, formState } = useForm({
		mode: "onChange"
	})
	return (
		<Modal show={!agreeTerms} size='lg'>
			<Modal.Header style={{ padding: '20px 40px 10px 40px' }}>
				<Modal.Title>Important Notice</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ paddingLeft: 40, paddingRight: 40 }}><b>PLAGIARISM AND UNAUTHORIZED REDISTRIBUTION</b><br />
				Here at Next Up, we take great care to carefully design and curate our content to ensure that the experience we deliver to you is as premium as can be. This means that we invest great amount of time and effort into what we provide.<br /><br />
				To plagiarize our content by recording, downloading, or re-sharing independently simply wouldn't do right by our content.<br /><br />
				Which is why we ask your cooperation to refrain from copying, downloading, sharing or redistributing our content outside of our platform.<br /><br />
				This helps keep us afloat, while protecting the integrity of our content.<br /><br />
				We hope to see you cooperate.<br /><br />
			</Modal.Body>
			<Modal.Footer style={{ padding: '0px 40px 30px 40px' }} className="modal-body">
				<form onSubmit={handleSubmit(submitHandler)} >
					<label className="checkboxContainer"><span>I will refrain from copying, downloading, sharing or redistributing the content provided to me by Next Up to outside personnel/outlets.</span>
						<input type="checkbox" name="ack1" ref={register({ required: true })} />
						<span className="checkmark"></span>
					</label>
					<button id="registerbtn" type="submit" className="registerbtn" disabled={!formState.isValid} style={{ backgroundColor: (formState.isValid ? 'black' : '#d4d2d2') }}>
						LET'S GET STARTED !
					</button>
				</form>
			</Modal.Footer>
		</Modal>
	)
}

export const Certi = props => (
	<Fragment>
		<g transform="translate(281.07 165.23)">
			<g transform="scale(0.4)">
				<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="award" class="svg-inline--fa fa-award fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
					<path fill="currentColor" d="M97.12 362.63c-8.69-8.69-4.16-6.24-25.12-11.85-9.51-2.55-17.87-7.45-25.43-13.32L1.2 448.7c-4.39 10.77 3.81 22.47 15.43 22.03l52.69-2.01L105.56 507c8 8.44 22.04 5.81 26.43-4.96l52.05-127.62c-10.84 6.04-22.87 9.58-35.31 9.58-19.5 0-37.82-7.59-51.61-21.37zM382.8 448.7l-45.37-111.24c-7.56 5.88-15.92 10.77-25.43 13.32-21.07 5.64-16.45 3.18-25.12 11.85-13.79 13.78-32.12 21.37-51.62 21.37-12.44 0-24.47-3.55-35.31-9.58L252 502.04c4.39 10.77 18.44 13.4 26.43 4.96l36.25-38.28 52.69 2.01c11.62.44 19.82-11.27 15.43-22.03zM263 340c15.28-15.55 17.03-14.21 38.79-20.14 13.89-3.79 24.75-14.84 28.47-28.98 7.48-28.4 5.54-24.97 25.95-45.75 10.17-10.35 14.14-25.44 10.42-39.58-7.47-28.38-7.48-24.42 0-52.83 3.72-14.14-.25-29.23-10.42-39.58-20.41-20.78-18.47-17.36-25.95-45.75-3.72-14.14-14.58-25.19-28.47-28.98-27.88-7.61-24.52-5.62-44.95-26.41-10.17-10.35-25-14.4-38.89-10.61-27.87 7.6-23.98 7.61-51.9 0-13.89-3.79-28.72.25-38.89 10.61-20.41 20.78-17.05 18.8-44.94 26.41-13.89 3.79-24.75 14.84-28.47 28.98-7.47 28.39-5.54 24.97-25.95 45.75-10.17 10.35-14.15 25.44-10.42 39.58 7.47 28.36 7.48 24.4 0 52.82-3.72 14.14.25 29.23 10.42 39.59 20.41 20.78 18.47 17.35 25.95 45.75 3.72 14.14 14.58 25.19 28.47 28.98C104.6 325.96 106.27 325 121 340c13.23 13.47 33.84 15.88 49.74 5.82a39.676 39.676 0 0 1 42.53 0c15.89 10.06 36.5 7.65 49.73-5.82zM97.66 175.96c0-53.03 42.24-96.02 94.34-96.02s94.34 42.99 94.34 96.02-42.24 96.02-94.34 96.02-94.34-42.99-94.34-96.02z">
					</path>
				</svg>
			</g>
		</g>
		<text x="365" y="470" style={{ fontSize: 30, fontWeight: 500 }} fill="white">CERTIFICATE</text>
	</Fragment>
)
export const NCE = props => (
	<g transform="translate(241.07 165.23)">
		<g transform="scale(0.7)" fill="white">
			<title>Logo-NF</title>
			<path d="M110.9,144.8l103.2,63.6c1.2-3.5,0.5-7.3-1.7-10.2L117.3,73.8c-1.6-2.1-3.9-3.5-6.4-4.1V144.8z" />
			<path d="M106.2,144.8V69.7c-2.6,0.5-4.8,2-6.4,4.1L4.7,198.2c-2.3,2.9-2.9,6.7-1.7,10.2L106.2,144.8z" />
			<path d="M108.5,149l-103,63.5c2.1,2.2,4.9,3.4,7.9,3.4c0,0,71.3-23.1,95.1-23.1s95.1,23.1,95.1,23.1c3,0,5.9-1.2,8-3.4L108.5,149z"
			/>
		</g>
		<g transform="scale(0.7)" fill="white">
			<path d="M266.4,76.2h12.8l9.8,60.2h-9.5l-1.7-12v0.2h-10.7l-1.7,11.8h-8.8L266.4,76.2z M276.7,116.4l-4.2-29.8h-0.2l-4.1,29.8
		H276.7z"/>
			<path d="M307.3,76.2h11.9l9.2,36h0.2v-36h8.4v60.2h-9.7l-11.3-43.9h-0.2v43.9h-8.4V76.2z" />
			<path d="M344,76.2h25.8v8.6h-16.3v15.9h13v8.6h-13v18.5h16.3v8.6H344V76.2z" />
			<path d="M383.7,105.6l-10.4-29.4h10l6.4,19.4h0.2l6.5-19.4h8.9l-10.4,29.4l10.9,30.8h-10l-6.9-21h-0.2l-7.1,21h-8.9L383.7,105.6z"
			/>
			<path d="M417.7,84.8h-9.9v-8.6h29.2v8.6h-9.9v51.6h-9.5V84.8z" />
			<path d="M455,122.1V76.2h9.5v46.6c0,4.3,1.8,5.8,4.8,5.8c3,0,4.8-1.5,4.8-5.8V76.2h9.1v45.9c0,9.6-4.8,15.1-14.1,15.1
		S455,131.7,455,122.1z"/>
			<path d="M489.9,76.2h13.9c9.5,0,14.1,5.2,14.1,14.9V97c0,9.6-4.6,14.9-14.1,14.9h-4.5v24.5h-9.5V76.2z M503.9,103.3
		c3,0,4.6-1.4,4.6-5.7v-7.1c0-4.3-1.6-5.7-4.6-5.7h-4.5v18.5H503.9z"/>
			<path d="M258.5,191.2v-32.3c0-9.3,4.8-14.8,13.9-14.8s13.9,5.5,13.9,14.8v6.4h-8.9v-7c0-4-1.9-5.6-4.7-5.6c-2.8,0-4.7,1.6-4.7,5.6
		v33.6c0,4,1.9,5.5,4.7,5.5c2.8,0,4.7-1.5,4.7-5.5v-9.2h8.9v8.5c0,9.3-4.8,14.8-13.9,14.8S258.5,200.5,258.5,191.2z"/>
			<path d="M291.7,190.9V145h9.5v46.6c0,4.3,1.8,5.8,4.8,5.8c3,0,4.8-1.5,4.8-5.8V145h9.1v45.9c0,9.6-4.8,15.1-14.1,15.1
		S291.7,200.5,291.7,190.9z"/>
			<path d="M326.6,145h14c9.8,0,14,4.6,14,13.8v3.7c0,6.2-2,10.1-6.2,11.9v0.2c4.7,1.5,6.3,5.9,6.3,12.7v10.6c0,2.9,0.1,5.1,1,7.3
		h-9.6c-0.5-1.5-0.9-2.5-0.9-7.4v-11c0-5.6-1.6-7.4-5.9-7.4H336v25.8h-9.5V145z M339.5,170.8c3.5,0,5.7-1.5,5.7-6.4v-4.6
		c0-4.3-1.5-6.2-4.8-6.2H336v17.2H339.5z"/>
			<path d="M368.5,145h12.8l9.8,60.2h-9.5l-1.7-12v0.2h-10.7l-1.7,11.8h-8.8L368.5,145z M378.8,185.2l-4.2-29.8h-0.2l-4.1,29.8H378.8z
		"/>
			<path d="M399.1,153.6h-9.9V145h29.2v8.6h-9.9v51.6h-9.5V153.6z" />
			<path d="M423,145h25.8v8.6h-16.3v15.9h13v8.6h-13v18.5h16.3v8.6H423V145z" />
			<path d="M454.3,145h14.4c9.5,0,14.1,5.2,14.1,14.9v30.4c0,9.6-4.6,14.9-14.1,14.9h-14.4V145z M468.5,196.6c3,0,4.8-1.5,4.8-5.8
		v-31.3c0-4.3-1.8-5.8-4.8-5.8h-4.8v43H468.5z"/>
			<path d="M502.9,145h25.8v8.6h-16.3v15.9h13v8.6h-13v18.5h16.3v8.6h-25.8V145z" />
			<path d="M531.6,145h9.5l6.2,46.7h0.2l6.2-46.7h8.7l-9.1,60.2h-12.6L531.6,145z" />
			<path d="M567,145h25.8v8.6h-16.3v15.9h13v8.6h-13v18.5h16.3v8.6H567V145z" />
			<path d="M598.2,145h11.9l9.2,36h0.2v-36h8.4v60.2h-9.7l-11.3-43.9h-0.2v43.9h-8.4V145z" />
			<path d="M642.3,153.6h-9.9V145h29.2v8.6h-9.9v51.6h-9.5V153.6z" />
		</g>
	</g>
)
export const NCW = props => (
	<g transform="translate(241.07 165.23)">
		<g transform="scale(0.7)" fill="white">
			<title>Logo-NF</title>
			<path d="M110.9,144.8l103.2,63.6c1.2-3.5,0.5-7.3-1.7-10.2L117.3,73.8c-1.6-2.1-3.9-3.5-6.4-4.1V144.8z" />
			<path d="M106.2,144.8V69.7c-2.6,0.5-4.8,2-6.4,4.1L4.7,198.2c-2.3,2.9-2.9,6.7-1.7,10.2L106.2,144.8z" />
			<path d="M108.5,149l-103,63.5c2.1,2.2,4.9,3.4,7.9,3.4c0,0,71.3-23.1,95.1-23.1s95.1,23.1,95.1,23.1c3,0,5.9-1.2,8-3.4L108.5,149z"
			/>
		</g>
		<g transform="scale(0.7)" fill="white">
			<path d="M266.4,76.2h12.8l9.8,60.2h-9.5l-1.7-12v0.2h-10.7l-1.7,11.8h-8.8L266.4,76.2z M276.7,116.4l-4.2-29.8h-0.2l-4.1,29.8
		H276.7z"/>
			<path d="M307.3,76.2h11.9l9.2,36h0.2v-36h8.4v60.2h-9.7l-11.3-43.9h-0.2v43.9h-8.4V76.2z" />
			<path d="M344,76.2h25.8v8.6h-16.3v15.9h13v8.6h-13v18.5h16.3v8.6H344V76.2z" />
			<path d="M383.7,105.6l-10.4-29.4h10l6.4,19.4h0.2l6.5-19.4h8.9l-10.4,29.4l10.9,30.8h-10l-6.9-21h-0.2l-7.1,21h-8.9L383.7,105.6z"
			/>
			<path d="M417.7,84.8h-9.9v-8.6h29.2v8.6h-9.9v51.6h-9.5V84.8z" />
			<path d="M455,122.1V76.2h9.5v46.6c0,4.3,1.8,5.8,4.8,5.8c3,0,4.8-1.5,4.8-5.8V76.2h9.1v45.9c0,9.6-4.8,15.1-14.1,15.1
		S455,131.7,455,122.1z"/>
			<path d="M489.9,76.2h13.9c9.5,0,14.1,5.2,14.1,14.9V97c0,9.6-4.6,14.9-14.1,14.9h-4.5v24.5h-9.5V76.2z M503.9,103.3
		c3,0,4.6-1.4,4.6-5.7v-7.1c0-4.3-1.6-5.7-4.6-5.7h-4.5v18.5H503.9z"/>
			<path d="M258.5,191.2v-32.3c0-9.3,4.8-14.8,13.9-14.8s13.9,5.5,13.9,14.8v6.4h-8.9v-7c0-4-1.9-5.6-4.7-5.6c-2.8,0-4.7,1.6-4.7,5.6
		v33.6c0,4,1.9,5.5,4.7,5.5c2.8,0,4.7-1.5,4.7-5.5v-9.2h8.9v8.5c0,9.3-4.8,14.8-13.9,14.8S258.5,200.5,258.5,191.2z"/>
			<path d="M299.3,145h12.8l9.8,60.2h-9.5l-1.7-12v0.2H300l-1.7,11.8h-8.8L299.3,145z M309.6,185.2l-4.2-29.8h-0.2l-4.1,29.8H309.6z"
			/>
			<path d="M326.5,145H340l6,43.1h0.2l6-43.1h13.5v60.2h-8.9v-45.6h-0.2l-6.9,45.6h-7.9l-6.9-45.6h-0.2v45.6h-8.3V145z" />
			<path d="M372.7,145h13.9c9.5,0,14.1,5.2,14.1,14.9v5.9c0,9.6-4.6,14.9-14.1,14.9h-4.5v24.5h-9.5V145z M386.7,172
		c3,0,4.6-1.4,4.6-5.7v-7.1c0-4.3-1.6-5.7-4.6-5.7h-4.5V172H386.7z"/>
			<path d="M405.6,190.9V145h9.5v46.6c0,4.3,1.8,5.8,4.8,5.8c3,0,4.8-1.5,4.8-5.8V145h9.1v45.9c0,9.6-4.8,15.1-14.1,15.1
		S405.6,200.5,405.6,190.9z"/>
			<path d="M438.9,190.9v-3.4h8.9v4.1c0,4.3,1.9,5.8,4.9,5.8c3,0,4.9-1.5,4.9-6c0-5-1.9-8.2-8.1-13.6c-7.9-7-10.5-11.9-10.5-18.7
		c0-9.5,4.9-15,14.1-15s13.8,5.5,13.8,15.1v2.5H458v-3.1c0-4.3-1.7-5.9-4.7-5.9c-3,0-4.7,1.6-4.7,5.8c0,4.4,2,7.6,8.2,13
		c7.9,7,10.4,11.8,10.4,19.3c0,9.8-5,15.3-14.3,15.3S438.9,200.5,438.9,190.9z"/>
			<path d="M484,145h9.1l4.2,46.3h0.2L502,145h10.3l4.5,46.3h0.2l4.2-46.3h8.2l-6.1,60.2h-11.8l-4.3-40.6H507l-4.3,40.6h-12.6L484,145
		z"/>
			<path d="M533.5,190.9v-31.6c0-9.6,5.1-15.1,14.4-15.1s14.4,5.5,14.4,15.1v31.6c0,9.6-5.1,15.1-14.4,15.1S533.5,200.5,533.5,190.9z
		 M552.7,191.5v-32.8c0-4.3-1.9-5.9-4.9-5.9c-3,0-4.9,1.6-4.9,5.9v32.8c0,4.3,1.9,5.9,4.9,5.9C550.8,197.4,552.7,195.8,552.7,191.5z
		"/>
			<path d="M568.5,145h14c9.8,0,14,4.6,14,13.8v3.7c0,6.2-2,10.1-6.2,11.9v0.2c4.7,1.5,6.3,5.9,6.3,12.7v10.6c0,2.9,0.1,5.1,1,7.3
		h-9.6c-0.5-1.5-0.9-2.5-0.9-7.4v-11c0-5.6-1.6-7.4-5.9-7.4H578v25.8h-9.5V145z M581.4,170.8c3.5,0,5.7-1.5,5.7-6.4v-4.6
		c0-4.3-1.5-6.2-4.8-6.2H578v17.2H581.4z"/>
			<path d="M603.2,145h9.5v25.4l12-25.4h9.5l-11.3,22.1l11.4,38.1h-9.9l-8-26.8l-3.8,7.7v19.2h-9.5V145z" />
			<path d="M636.6,190.9v-3.4h8.9v4.1c0,4.3,1.9,5.8,4.9,5.8c3,0,4.9-1.5,4.9-6c0-5-1.9-8.2-8.1-13.6c-7.9-7-10.5-11.9-10.5-18.7
		c0-9.5,4.9-15,14.1-15s13.8,5.5,13.8,15.1v2.5h-8.9v-3.1c0-4.3-1.7-5.9-4.7-5.9c-3,0-4.7,1.6-4.7,5.8c0,4.4,2,7.6,8.2,13
		c7.9,7,10.4,11.8,10.4,19.3c0,9.8-5,15.3-14.3,15.3S636.6,200.5,636.6,190.9z"/>
			<path d="M670.2,145h9.5v24.5h10.1V145h9.5v60.2h-9.5v-27.1h-10.1v27.1h-9.5V145z" />
			<path d="M705.6,190.9v-31.6c0-9.6,5.1-15.1,14.4-15.1s14.4,5.5,14.4,15.1v31.6c0,9.6-5.1,15.1-14.4,15.1S705.6,200.5,705.6,190.9z
		 M724.9,191.5v-32.8c0-4.3-1.9-5.9-4.9-5.9c-3,0-4.9,1.6-4.9,5.9v32.8c0,4.3,1.9,5.9,4.9,5.9C723,197.4,724.9,195.8,724.9,191.5z"
			/>
			<path d="M740.7,145h13.9c9.5,0,14.1,5.2,14.1,14.9v5.9c0,9.6-4.6,14.9-14.1,14.9h-4.5v24.5h-9.5V145z M754.6,172
		c3,0,4.6-1.4,4.6-5.7v-7.1c0-4.3-1.6-5.7-4.6-5.7h-4.5V172H754.6z"/>
		</g>
	</g >
)

export const Blob = props => {
	const blob1Ref = useRef(null)
	const blob2Ref = useRef(null)
	useEffect(() => {
		blob1Ref.current && blob2Ref.current && KUTE.fromTo(
			blob1Ref.current,
			{ path: blob1Ref.current },
			{ path: blob2Ref.current },
			{ repeat: 999, duration: 3000, yoyo: true }
		).start();
	}, [blob1Ref, blob2Ref])

	// const BlobIcon = components[props.title];

	return (
		<div className={`blobBox ${props.color}`}>
			<div style={{ position: 'relative', width: '30%' }}>
				<svg className='blob' version="1.1" viewBox="0 0 900 600" xmlns="http://www.w3.org/2000/svg">
					<defs>
						<radialGradient id="blue" cx="100%" cy="100%" r="120.71067811865476%" >
							<stop offset="0%" stop-color='#26D0CE' />
							<stop offset="110%" stop-color="#2B32B2" />
						</radialGradient>
						<radialGradient id="purple" cx="100%" cy="100%" r="120.71067811865476%" >
							<stop offset="0%" stop-color="#af89e0" />
							<stop offset="100%" stop-color="#553dd4" />
						</radialGradient>
						<radialGradient id="orange" cx="100%" cy="100%" r="120.71067811865476%" >
							<stop offset="0%" stop-color="#ffe259" />
							<stop offset="100%" stop-color="#f83600" />
						</radialGradient>
					</defs>
					<g transform="translate(450.37 316.93)">
						<path classname='blobby' fill={`url(#${props.color})`} id="blob1" ref={blob1Ref} d="m179.9-190.6c38.5 49.2 44.8 119.9 36.8 182.6s-30.3 117.4-68.8 159c-38.5 41.7-93.2 70.3-152.3 74.7-59 4.4-122.4-15.6-162.7-57.2-40.4-41.7-57.6-105.1-52.5-163.3 5.2-58.2 32.9-111.3 73.2-160.4 40.3-49.2 93.4-94.5 155.2-103.3 61.9-8.9 132.6 18.7 171.1 67.9" />
					</g>
					<g transform="translate(430.02 314.17)" style={{ visibility: 'hidden' }}>
						<path classname='blobby' fill={`url(#${props.color})`} id="blob2" ref={blob2Ref} d="m182-177c50 45 84 111 83.4 176.4s-35.8 130.2-85.8 167.7-114.8 47.7-180.9 49-133.5-6.3-173.5-43.8-52.6-104.9-50.4-170.1c2.3-65.1 19.3-128.1 59.3-173.1s103-72 167.4-73.6c64.5-1.5 130.5 22.5 180.5 67.5" />
					</g>
					{props.title}
				</svg>
			</div>
			<div>
				Next Up Campus Workshops take the same great experience of an NCE and trickle it down to educational institutions.<br /><br />
				NCWs are more affordable, shortened courses in the form of workshops, seminars or internship programs.<br /><br />
				NCWs also include an official NCW Certificate issued directly from Next Up, adding higher value to a resume.<br />
			</div>
		</div>
	)
}
export const McqModal = props => {
	const { register, handleSubmit, getValues } = useForm()
	const [result, setResult] = useState([])
	const [score, setScore] = useState()
	const [minutes, setMinutes] = useState(props.mcqData[props.mcqModalTitle].length);
	const [seconds, setSeconds] = useState(0);
	const [submitted, setSubmitted] = useState(false);

	const mcqEvaluation = (d) => {
		setSubmitted(true)
		let _score = 0
		props.mcqData[props.mcqModalTitle].forEach((item, i) => {
			let res = item.answer === Object.values(d)[i]
			setResult(r => [...r, res])
			res && _score++
		});
		setScore(_score + '/' + props.mcqData[props.mcqModalTitle].length);
		props.saveScore(props.userId, props.mcqModalTitle, _score + '/' + props.mcqData[props.mcqModalTitle].length)
	}

	useEffect(() => {
		let myInterval = setInterval(() => {
			if (seconds > 0) setSeconds(seconds - 1);
			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(myInterval)
					if (!submitted) mcqEvaluation(getValues())
				} else {
					setMinutes(minutes - 1);
					setSeconds(59);
				}
			}
		}, 1000)
		return () => {
			clearInterval(myInterval);
		};
	});

	return (
		<Modal size='xl' show={props.mcqModal} onHide={() => { props.setMcqModal(false); props.refreshUser(); }} >
			<Modal.Header closeButton>
				<Modal.Title style={{ margin: '2px 15px 0  15px' }}>Audit {Object.keys(props.mcqData).indexOf(props.mcqModalTitle) + 1}: {moment(props.mcqModalTitle, 'YYYY-MM-DD').format('MMM D')}</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ padding: ' 0px 40px ' }} >
				<br />
				<div className='vidDescription' style={{ color: 'grey', marginBottom: 0 }}>Note: Tests are timed at 1 minute per question with +1/-0 marking.</div>
				<div className='timer'>{minutes.toString().padStart(2, 0)}:{seconds.toString().padStart(2, 0)}</div>
				<form onSubmit={handleSubmit(mcqEvaluation)} style={{ width: '100%' }}>
					{
						props.mcqData[props.mcqModalTitle].map((item, index) => (
							<div key={index}>
								<h4 style={{ margin: ' 30px 0px  10px 0px' }}>{`${index + 1}. ${item.question}`}</h4>
								{
									item.options.map((option, i) => (
										<label key={i} className='containerRadio'>{option}
											<input type="radio" name={index} value={option} ref={register()} disabled={result.length > 0} />
											<span className="checkmark" style={{ width: 20, height: 20 }}></span>
										</label>
									))
								}
								{
									result.length > 0 && <div style={{ backgroundColor: result[index] ? '#E6F4EA' : '#fce8e6', borderRadius: 4, margin: ' 30px 0px 15px 0px', padding: 10 }}>
										Correct Answer : {item.answer}
									</div>
								}
							</div>
						))
					}
					{
						result.length === 0 ?
							<button type="submit" className='registerButton' style={{ marginLeft: '30%', marginBottom: 30, marginTop: 20 }}>SUBMIT</button> :
							<div style={{ fontSize: 24, fontWeight: 'bold', margin: '30px 35%' }}>Score: {score}</div>
					}
				</form>
			</Modal.Body>
		</Modal>
	)
}

export const DecryptModal = props => {
	const { register, handleSubmit, setError, formState: { errors } } = useForm()

	const decrypt = (str, key) => { return AES.decrypt(str, key).toString(Utf8) }

	const submitHandler = (d) => {
		try {
			let controller = decrypt(props.passwords.controller, d.key)
			if (controller === 'success') {
				props.setPasswords(p => {
					return {
						controller: 'success',
						accounts: p.accounts.map(a => {
							return { ...a, pass: decrypt(a.pass, d.key), pass2: a.pass2 && decrypt(a.pass2, d.key) }
						})
					}
				})
				if (props.selectedPassword === undefined) props.set(false)
			} else setError("key", { type: "manual", message: "Decryption Failed", })
		}
		catch (e) {
			setError("key", { type: "manual", message: "Invalid Decryption Key", })
		}
	}

	return (
		<Modal size='s' show={props.show} onHide={() => { props.set(false); props.setSelectedPassword(); }}  >
			<Modal.Header closeButton className="modal-header">
				<Modal.Title style={{ margin: '2px 15px 0  15px' }}>Password</Modal.Title>
			</Modal.Header>
			<Modal.Body className="modal-body">
				{props.passwords.controller === 'success' && props.selectedPassword !== undefined ? <Fragment>
					<b>{props.passwords.accounts[props.selectedPassword].name}</b><br/>
					<span>{props.passwords.accounts[props.selectedPassword].email}</span><br/>
					<span>{props.passwords.accounts[props.selectedPassword].pass}</span><br/>
					<span>{props.passwords.accounts[props.selectedPassword].pass2}</span><br/>
				</Fragment> : <Fragment>
					<form onSubmit={handleSubmit(submitHandler)}>
						<Form.Group>
							<Form.Control type="text" name="username" value="passwords" style={{visibility:'hidden', position:'absolute', top:0}}/>
							<Form.Control type="password" placeholder="Decryption Key" name='key' className="textfield" ref={register()} isInvalid={errors.key} />
						</Form.Group>
						{errors.key && <p>{errors.key.message}</p>}
						<button id="registerbtn" type="submit" className="registerbtn">Decrypt</button>
					</form>
				</Fragment>
				}

			</Modal.Body>
		</Modal>
	)
}

export const DragDropModal = props => {
	const [assignmentData, setAssignmentData] = useState([]);
	const [filesTooLarge, setFilesTooLarge] = useState(false);

	const { register, handleSubmit } = useForm()

	useEffect(() => {
		props.db.getDatabase('users/' + props.userId + '/progress/assignments', p => {
			p && props.item.title && p[props.item.title] && setAssignmentData(p[props.item.title])
		})
	}, [props])

	const onDrop = useCallback(acceptedFiles => {
		let largeFiles = acceptedFiles.filter(a => a.size > 25000000)
		if (largeFiles.length > 0) setFilesTooLarge(true)
		else acceptedFiles.map((item, index) => props.db.setStorageAssignments(props.userId, item, props.item.title, index))
	}, [props])

	const deleteHandler = (url, i, isDrive) => {
		if (!isDrive)
			props.db.deleteStorageItem(props.userId, url, props.item.title, i, () => {
				setAssignmentData(a => a.filter((f, _i) => _i !== i))
				props.refreshUser()
			})
		else props.db.deleteDriveItem(props.userId, props.item.title, i, () => {
			setAssignmentData(a => a.filter((f, _i) => _i !== i))
			props.refreshUser()
		})
	}

	const submitHandler = (d) => {
		props.db.setDriveAttachment(props.userId, props.item.title, 0, d.url)
	}

	const { getRootProps, getInputProps } = useDropzone({ onDrop })
	return (
		<Modal size='lg' show={props.assignmentsModal} onHide={() => props.setAssignmentsModal(false)}  >
			<Modal.Header closeButton className="modal-header">
				<Modal.Title style={{ margin: '2px 15px 0  15px' }}>{props.item.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body className="modal-body">
				{
					assignmentData.length > 0 ? assignmentData.map((item, i) => (
						<div className='vid-container' key={i} style={{ borderLeft: '8px solid orange', padding: 15 }}>
							<div onClick={() => deleteHandler(item.url, i, item.title.includes("Drive"))}>
								<b className="fas pressable" style={{ color: 'grey', float: 'right', marginTop: 20, marginRight: 10 }}>{"\uf00d"}</b>
							</div>
							<span className='vidTitle' style={{ marginBottom: 0, marginTop: 8 }}>{item.title}</span>
							{item.size ? <span className='vidDescription' style={{ color: 'grey' }}>{item.size > 1000000 ? `${(item.size / 1048576).toFixed(1)} MB` : `${(item.size / 1024).toFixed(1)} KB`}</span> :
								<span className='vidDescription' style={{ color: 'grey' }}>{moment(item.date, "YYYY-MM-DD").format("MMM D")}</span>}
						</div>
					)) : (
						!filesTooLarge ?
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								<div className='dropzone'>Drag and drop files here, or click to select files</div>
							</div> : <Fragment>
								<span style={{ fontSize: 16, fontWeight: 500 }}>Files are larger than the 25 MB limit. Please provide a drive link instead.</span>
								<form onSubmit={handleSubmit(submitHandler)}>
									<Form.Group>
										<Form.Control type="text" placeholder="Google Drive Link" name='url' className="textfield" ref={register({ pattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/ })} />
									</Form.Group>
									<button id="registerbtn" type="submit" className="registerbtn">Attach</button>
								</form>
							</Fragment>
					)
				}

			</Modal.Body>
		</Modal>
	)
}

export const VidPlayerModal = props => {
	const downloadFile = (i) => {
		window.open('old/' + props.sessionTitle.attachments[i].url, "_blank")
	}
	return (
		<Modal size='xl' show={props.showVidModal} onHide={() => props.setShowVidModal(false)} >
			<Modal.Header closeButton>
				<Modal.Title style={{ margin: '2px 15px 0  15px' }}>{props.sessionTitle.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div style={{ position: 'relative', padding: 20 }}>
					<ReactPlayer url={props.sessionTitle.url} width='100%' height='100%' controls={true} style={{ margin: '16px 0 28px 0' }} onStart={props.onStart} />
				</div>
				<div style={{ margin: '0 15px 15px 15px' }}> {props.sessionTitle.description} </div><br />

				<h3 style={{ margin: '0 15px 15px 15px' }}>Attachments</h3>
				{
					props.sessionTitle.attachments && props.sessionTitle.attachments.map((item, fi) => (
						<div className='attachment' >
							<div className='sessionTitle'>{item.name}</div>
							<div className="gMeetButton zoom-m" alt="" onClick={() => downloadFile(fi)}>
								<FontAwesomeIcon icon={faFileDownload} style={{ fontSize: 25, margin: '10px 0 0 13px' }} />
							</div>
							<div className='smallText' style={{ width: '40%' }}>{item.type}</div><br />
						</div>
					))
				}
			</Modal.Body>
		</Modal>
	)
}

export const AgendaModal = props => {
	const [useDarkMode, setDarkMode] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches)

	useEffect(() => {
		window.matchMedia('(prefers-color-scheme: dark)').addListener(e => setDarkMode(e.matches));
	}, [])

	return (
		<Modal size='xl' show={props.showAgendaModal} onHide={() => props.setShowAgendaModal(false)} >
			<Modal.Header closeButton>
				<Modal.Title style={{ margin: '2px 15px 0  15px' }}>Agenda</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{
					props.calendarItems.length > 0 && props.calendarItems.map((item, i) => (
						<Fragment key={i}>
							{(i === 0 || !moment(item.start).isSame(moment(props.calendarItems[i - 1].start), 'day')) && <div style={{ fontWeight: 600, fontSize: 20, margin: 10 }}>{moment(item.start).format('MMM D')}</div>}
							<div className='calendarInfoAgenda' style={{ borderColor: props.getCalendarItemColor(item.title, item.url && true), backgroundColor: moment(item.start).isBefore(moment(), 'day') ? (useDarkMode ? 'rgb(34, 33, 33)' : '#eee') : (useDarkMode ? 'rgb(52, 52, 53)' : 'white') }}>
								<div className='sessionTitle' style={{ fontWeight: 500 }}>{item.title}</div>
								{item.url && <div className="gMeetButton pressable" alt="" onClick={() => window.open(item.url, '_blank').focus()}>
									<img src={require('./images/main/meet.png')} className='gMeetLogo' alt='' />
								</div>}

								<div className='smallText' style={{ width: '40%' }}>{moment(item.start).format('h:mm A')} - {moment(item.end).format('h:mm A')}</div><br />
							</div> <br />
						</Fragment>
					))
				}
			</Modal.Body>
		</Modal>
	)
}

export const BgDefault = props => (
	<img className='MainBg' alt="" src='bg.png' />
)

export const BgStatic = props => (
	<img className='MainBg' alt="" src='bg.png' style={{ position: 'absolute' }} />
)

export const RegisterBg = props => (
	<img className='MainBg' alt="" src='bg.png' style={{ opacity: '5%', overflow: 'hidden', zindex: '1' }} />
)

export const Sitemap = props => (
	<div className="footer">
		<div style={{ display: 'inline-flex', width: '100%' }}>
			<div className='siteMapBox'>
				<h6>Sitmap</h6>
				<a href="/">Home</a><br /><br />
				< a href="/contact">Contact</a><br /><br />
				< a href="/about">About</a><br /><br />
				< a href="/login">Login</a><br /><br />
			</div>
			<div className='siteMapBox'>
				<h6>Courses</h6>
				< a href="/ar">Creating AR</a><br /><br />
				< a href="/ml">Fundamental ML</a><br /><br />
				< a href="/web-design">Useful Web Design</a><br /><br />
				< a href="/ui">Reasonable UI</a><br /><br />
				< a href="/graphic-design">Employable Graphic Design</a><br /><br />
				< a href="/digital-marketing">Digital Marketing</a><br /><br />
				< a href="/presenting">Art of Presenting</a><br /><br />
			</div>
			<div className='siteMapBox'>
				<h6>Documents</h6>
				<a href="/lookup" >Document Lookup</a><br /><br />
				<a href="/login" >Document Download</a><br /><br />
			</div>
			<a href="https://www.instagram.com/nextup.india/" ><FontAwesomeIcon icon={faInstagram} className='social' /></a><br /><br />
			<a href="https://www.linkedin.com/company/next-up-learning/about/" ><FontAwesomeIcon icon={faLinkedinIn} className='social' /></a>&nbsp;&nbsp;&nbsp;&nbsp;<br /><br />
		</div>
		<p>&copy; Autre Learning LLP. 2020. Read our <a href="tnc.html"><b>Terms and Conditions</b></a>, <a href="privacy.html"><b>Privacy Policy</b></a> and <a href="refund.html"><b>Refund Policy</b></a>.</p>
	</div>
)