import { BrowserRouter as Router, Route } from 'react-router-dom';
import CreatingAR from './CreatingAR';
import FundamentalML from './FundamentalML';
import UsefulWebDesign from './UsefulWebDesign';
import ReasonableUI from './ReasonableUI';
import EmployableGraphicDesign from './EmployableGraphicDesign';
import DigitalMarketing from './DigitalMarketing';
import ArtofPresenting from './ArtofPresenting';
import Dashboard from './Dashboard';
import Contact from './Contact';
import Lookup from './Lookup';
import Register from './Register';
import About from './About';
import React from 'react';
import Main from './Main';
import Login from './Login';
import Gallerys from './Gallery';
import Reciept from './Reciept';
import AdminConsole from './AdminConsole';
import Certificate from './Certificate';
import RenderDocuments from './RenderDocument';
import './App.css';

import DB, { db } from './DB';

function App() {
	return (
		<DB.Provider value={db}>
			<Router>
				<div className="App">
					<Route exact path="/" component={Main} />
					<Route path="/ar" render={(props) => <CreatingAR {...props} />} />
					<Route path="/ml" render={(props) => <FundamentalML {...props} />} />
					<Route path="/web-design" render={(props) => <UsefulWebDesign {...props} />} />
					<Route path="/ui" render={(props) => <ReasonableUI {...props} />} />
					<Route path="/graphic-design" render={(props) => <EmployableGraphicDesign {...props} />} />
					<Route path="/digital-marketing" render={(props) => <DigitalMarketing {...props} />} />
					<Route path="/presenting" render={(props) => <ArtofPresenting {...props} />} />
					<Route path="/register" render={(props) => <Register {...props} />} />
					<Route path="/contact" render={(props) => <Contact {...props} />} />
					<Route path="/about" render={(props) => <About {...props} />} />
					<Route path="/lookup" render={(props) => <Lookup {...props} />} />
					<Route path="/login" render={(props) => <Login {...props} />} />
					<Route path="/dashboard" render={(props) => <Dashboard {...props} />} />
					<Route path="/admin-console" render={(props) => <AdminConsole {...props} />} />
					<Route path="/gallery" render={(props) => <Gallerys {...props} />} />
					<Route path="/reciept" render={(props) => <Reciept {...props} />} />
					<Route path="/renderdoc" render={(props) => <RenderDocuments {...props} />} />
					<Route path="/certificate" render={(props) => <Certificate {...props} />} />
				</div>
			</Router>
		</DB.Provider>
	);
}

export default App;