import React, { createContext } from 'react';

import config from './config';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/analytics';

import moment from 'moment';

class DB extends React.Component {
	constructor(props) {
		super(props);
		if (!firebase.apps.length) {
			firebase.initializeApp(config);
		}
	}

	getDatabase = (course, callback) => {
		let ref = firebase.database().ref(course);
		var data;

		ref.on('value', async (snapshot) => {
			data = await snapshot.val()
			callback(data)
		})
	}

	getProfilePic = (accno, callback) => {
		let ref = firebase.storage().ref('dp/' + accno + '.jpg');
		ref.getDownloadURL().then(callback).catch(err => console.log(err))
	}

	login = (email, pass, successCallback, errorCallback) => {
		firebase.auth().signInWithEmailAndPassword(email, pass).then((data) => {
			this.getDatabase(`/${email.split('@')[1] === 'nextup.co.in' ? 'employees' : 'users'}/${data.user.uid}`, successCallback)
		}).catch(errorCallback);
	}

	loginFetch = (callback) => {
		firebase.auth().onAuthStateChanged((user) => {
			if (user)
				this.getDatabase(`/${user.email.split('@')[1] === 'nextup.co.in' ? 'employees' : 'users'}/${user.uid}`, callback)
			else callback()
		});
	}

	newRegistration = (txnid, userInfo, eventId, amount, callback) => {
		let d = firebase.database();
		let a = firebase.auth();

		a.createUserWithEmailAndPassword(userInfo.email, userInfo.mobile).then(() => {
			a.onAuthStateChanged((user) => {
				user.updateProfile({ displayName: userInfo.name, phone: userInfo.mobile }).then(() => {
					d.ref('registrations/' + eventId).child(txnid).set({ email: userInfo.email, uid: user.uid, amount, status: 'inprogress' })
					d.ref('users/').child(user.uid).set({ ...userInfo, course: 'ML' })
					callback()
				})
			});
		})
	}

	deleteStorageItem = (userId, path, topic, index, callback) => {
		firebase.database().ref('users/' + userId + '/progress/assignments').child(topic + '/' + index).remove();
		firebase.storage().ref(path).delete().then(callback)
	}

	deleteDriveItem = (userId, topic, index, callback) => {
		firebase.database().ref('users/' + userId + '/progress/assignments').child(topic + '/' + index).remove().then(callback)
	}

	setQRCode = (dept, date, id, pass, purpose) => {
		console.log('setQRCode');
		firebase.database().ref('documents/' + dept).on('value', async (snapshot) => {
			let data = await snapshot.val()
			if (data && data[dept]) return
			else firebase.database().ref('documents/' + dept).child((id - 1)).set({ date: date, id: id, pass: pass, purpose: purpose });
		});
	}

	setMcqScore = (userId, title, score) => {
		firebase.database().ref('users/' + userId + '/progress/mcq').on('value', async (snapshot) => {
			let data = await snapshot.val()
			if (data && data[title]) return
			else firebase.database().ref('users/' + userId + '/progress/mcq').child(title).set(score);
		});
	}

	getMcqScore = (userId, title, callback) => {
		firebase.database().ref('users/' + userId + '/progress/mcq').on('value', async (snapshot) => {
			let data = await snapshot.val()
			if (data && data[title]) callback(data)
			else callback()
		});
	}

	getQRCodeDoc = (course,qrValue,callback)=>{
		console.log(course,qrValue);
		firebase.database().ref('documents/'+ course).orderByChild('pass').equalTo(qrValue).on('value', async (snapshot) => {
			let data = await snapshot.val()
			console.log("function");
			console.log(Object.values(data)[0]);
			if (data) callback(Object.values(data)[0])
			else callback()
		});
	}
	updateTxnStatus = ({ eventId, txnId, status }) => {
		firebase.database().ref('registrations/' + eventId).child(txnId).update({ status: status === 's' ? 'success' : 'failed' })
	}

	edit = (callback) => {
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				console.log(user);
				user.updateProfile({
					email: user.email,
					name: user.name,
				}).then(function () {
					console.log("success");
					callback && callback()
				}).catch(function (error) {
					console.log(error);
				});
			}
		});
	}

	updatePass = (callback) => {
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				var newPassword;
				user.updatePassword(newPassword).then(function () {
					console.log("success");
				}).catch(function (error) { });
			} else {
				callback()
			}
		});
	}

	videoClickAnalytics = (id, name, user, phone) => {
		console.log(id, name, user);
		const analytics = firebase.analytics();
		analytics.logEvent('select_content', {
			content_type: 'video',
			content_id: id,
			items: [{ name, user }]
		});
		!id.split('ML_V_D')[1].includes('_') && firebase.database().ref('users/' + phone + '/progress/vidViewed').child(id).set(true)
	}

	logout = (callback) => {
		firebase.auth().signOut().then(callback).catch(error => console.log(error));
	}

	getStorageItem = (path, callback) => {
		firebase.storage().ref(path).getDownloadURL().then(callback).catch(function (error) { console.log(error); });
	}

	setDriveAttachment = (userId, title, index, url) => {
		firebase.database().ref('users/' + userId + '/progress/assignments').child(title + '/' + index).set({ title: "Drive Attachment", date: moment().format('YYYY-MM-DD'), url });
	}

	setStorageAssignments = (userId, item, title, index) => {
		firebase.database().ref('users/' + userId + '/progress/assignments').child(title + '/' + index).set({ title: item.name, size: item.size, date: moment().format('YYYY-MM-DD'), url: 'user_submissions/' + userId + `_${item.name}` });

		firebase.storage().ref('user_submissions/' + userId + `_${item.name}`).put(item).then((snapshot) => {
			console.log('Uploaded a blob or file!');
		});
	}

	listSubFolders = (path) => {
		return firebase.storage().ref(path).listAll()
	}

	acceptNDA = (user, callback) => {
		firebase.database().ref('users/' + user.phone + '/progress/').child('acceptNDA').set(true);
		callback()
	}

	logout = (callback) => {
		firebase.auth().signOut().then(callback).catch((e) => callback());
	}

}

export const db = new DB();
export default DB = createContext()