import { Header, BgDefault, Sitemap } from "./Components";
import React from "react";

function Contact() {
  return (
    <div>
      <BgDefault />
      <Header />
      <h3 style={{ marginTop: 100 }}>Contact Us</h3>
      <h4>General & Institutional Enquiries</h4>
      <div className="vid-container" style={{  padding: 20 }}>
        <b>Nishank Swamy D N</b><br />
        MD, Institutional Operations<br />
        +91 99866 34653<br />
        md@nextup.co.in<br />
      </div>
      <h4>Public Relations & Product Enquiries</h4>
      <div className="vid-container" style={{  padding: 20 }}>
        <b>Nishank Swamy D N</b><br />
        MD, Institutional Operations<br />
        +91 99866 34653<br />
        md@nextup.co.in<br />
      </div>

      <h3>Our Team</h3>
      <div className="vid-container" style={{  padding: 20 }}>
        <b>Menahi Shayan</b><br />
        CTO & Product Manager<br />
        +91 89717 29383<br />
        shayan@nextup.co.in<br />
      </div>
      <div className="vid-container" style={{  padding: 20 }}>
        <b>Nishank Swamy D N</b><br />
        MD, Institutional Operations<br />
        +91 99866 34653<br />
        nishank@nextup.co.in<br />
      </div>

      <h3>Corporate Office</h3>
      <iframe title="Corporate Office Address" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.611873831801!2d77.60744531482152!3d12.932648990881471!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTLCsDU1JzU3LjUiTiA3N8KwMzYnMzQuNyJF!5e0!3m2!1sen!2sus!4v1580394027212!5m2!1sen!2sus"
        width="650" height="200" class="map" frameborder="0" style={{ border: 0 }} allowfullscreen=""></iframe>
      <br />
      Autre Learning LLP, No. 27/1, 4th Cross, 3rd Main,<br />Venkateshwara Layout, SG Palya, Bangalore - 560029 <br />
      <br /><b>Working Hours</b><br />
      Mon - Fri, 9:00 AM to 7:00 PM<br />
      <Sitemap />
    </div>
  );
}

export default Contact;
