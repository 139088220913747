import {Header} from './Components' ;
import React from 'react';

function WebDesign() {
  	return (
		<div><Header /></div>
	);
}

export default WebDesign;
