import { Header, BgDefault, Sitemap } from './Components';
import React, { useEffect, useState, useContext } from 'react';
import Gallery from "react-photo-gallery";
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import DB from './DB';
import { Fragment } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import moment from 'moment';

const debounce = (func, wait, immediate) => {
    let timeout;
    return function () {
        const context = this,
            args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export const ImageModal = props => {
    return (
        <Modal show={props.show} size='xl' onHide={() => props.set(false)}>
            <Modal.Header style={{ padding: '20px 40px 10px 40px' }}>
                <Modal.Title>{props.metadata.title} @ {props.metadata.venue}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ paddingLeft: 40, paddingRight: 40 }}>
                <LazyLoadImage
                    effect="blur"
                    src={props.url}
                    style={{height:'100%',width:'100%'}}
                    alt={props.metadata.title}
                    visibleByDefault={true}
                    placeholder={<Spinner animation="border" />}
                />
                <hr />
                <div className='gallerySubtitle'>{props.metadata.description}, {moment(props.metadata.date, "YYYY-MM").format('MMMM YYYY')}</div>
            </Modal.Body>
        </Modal>
    )
}

function Gallerys() {
    const db = useContext(DB)

    const [metadata, setMetadata] = useState({})
    const [photos, setPhotos] = useState({})
    const [images, setImages] = useState({});
    const [largeImages, setLargeImages] = useState({});

    const [loadedAll, setLoadedAll] = useState({});

    const [showImageModal, setImageModal] = useState(false)
    const [selectedImage, setSelectedImage] = useState()

    const MIN_IMAGE_COUNT = 6
    const loadMorePhotos = debounce((name) => {
        setImages(img => {
            let newImages = { ...img, [name]: photos[name] }
            return newImages
        })
        setLoadedAll(l => {
            return { ...l, [name]: true }
        })
    }, 200);

    useEffect(() => {
        const getImages = async () => {
            try {
                let subFolders = await db.listSubFolders('photo')
                subFolders.prefixes.forEach(async (eachSubFolder) => {
                    setLoadedAll(l => {
                        return { ...l, [eachSubFolder.name]: false }
                    })
                    let folder = await eachSubFolder.listAll()
                    folder.items.forEach(async (image) => {
                        let url = await image.getDownloadURL()
                        if (image.name.includes('thumb_')) {
                            let payload = { src: url, height: 3, width: 4, name: image.name.split('thumb_')[1] }
                            setPhotos(p => { return { ...p, [eachSubFolder.name]: p[eachSubFolder.name] ? [...p[eachSubFolder.name], payload] : [payload] } })
                            setImages(p => {
                                var newPhotos
                                if (p[eachSubFolder.name])
                                    newPhotos = {
                                        ...p, [eachSubFolder.name]:
                                            p[eachSubFolder.name].length < MIN_IMAGE_COUNT ? [...p[eachSubFolder.name], payload] : p[eachSubFolder.name]
                                    }
                                else newPhotos = {
                                    ...p, [eachSubFolder.name]: [payload]
                                }
                                return newPhotos
                            })
                        } else {
                            setLargeImages(p => { return { ...p, [image.name]: url } })
                        }
                    });
                })
            } catch (e) {
                console.log(e);
            }
        }
        getImages()
        db.getDatabase('/gallery', setMetadata)
    }, [db])

    return (
        <Fragment>
            <div className='galleryBox'>
                <BgDefault />
                <Header />
                {showImageModal && <ImageModal {...{ ...selectedImage, ...{ show: showImageModal, set: setImageModal } }} />}
                <h3 style={{ marginTop: 100 }}>Gallery</h3>
                {
                    Object.keys(images).length > 0 ? Object.keys(images).sort().map((item, i) => (
                        <Fragment key={i}>
                            <div className='galleryTitle'>{metadata[item].title} @ {metadata[item].venue}</div>
                            <div className='gallerySubtitle'>{metadata[item].description}</div>
                            <div className='gallerySubtitle'>{moment(metadata[item].date, "YYYY-MM").format('MMMM YYYY')}</div><br />
                            <div className='galleryContainer'>
                                <Gallery photos={images[item]}
                                    renderImage={(props) => (
                                        <div key={props.index} style={{ margin: 5 }}>
                                            <LazyLoadImage
                                                height={180} effect="opacity"
                                                
                                                src={props.photo.src}
                                                width={240}
                                                alt={metadata[item].title}
                                                className='galleryImg'
                                                placeholder={<Spinner animation="border" />}
                                                onClick={() => { setSelectedImage({ url: largeImages[props.photo.name], metadata: metadata[item]}); setImageModal(true) }}
                                            />
                                            {
                                                props.index === MIN_IMAGE_COUNT - 1 && images[item].length === MIN_IMAGE_COUNT && !loadedAll[item] && <button
                                                    style={{ height: 180, width: 240, backgroundColor: 'black', fontSize: 20, color: 'white', fontWeight: 'bold', border: 'none', marginLeft: 10 }}
                                                    onClick={() => loadMorePhotos(item)} >Load More</button>
                                            }
                                        </div>
                                    )}
                                />
                            </div>
                        </Fragment>
                    )) : <Spinner animation="border" style={{ margin: '25%' }} />
                }

            </div>
            <Sitemap />
        </Fragment>
    );
}

export default Gallerys;