import { Header, Sitemap } from './Components';
import React from 'react';
import './Components.css'

function About() {
	return (
		<div>
			<Header />
			<div className='about'>
				<div style={{ display: 'inline-flex', width: '100%' }}>
					<div style={{ display: 'inline-block', marginRight: 50 }}>
						<img alt="" src={require("./images/vector/code.svg")} height='170' />
					</div>
					<div style={{ display: 'inline-block', width: '70%', marginTop: 20 }}>
						<h3>Who Are We?</h3>
						<span>Next Up is a Bengaluru based ed-tech startup that believes in learning with passion. We believe in learning principles driven by inciting interest from within, so that you can invest your time in doing something you actually love.
						</span>
					</div>
				</div>

				<div style={{ display: 'inline-flex', width: '100%', marginTop: 60 }}>
					<div style={{ display: 'inline-block', width: '70%', marginTop: 20 }}>
						<h3>What Makes Us Different?</h3>
						<span>We believe that the experience of a live, face-to-face session is absolutely unparalleled to learning by recorded videos.<br />
							The sheer factor of human interaction, by nature, incites a cognitive response that is irreplaceable by recorded videos.<br />
							This, combined with the curated atmosphere of select minds that share common ideas and interests, allows for forming of interpersonal bonds like no other.<br />
						</span>
					</div>
					<div style={{ display: 'inline-block', marginLeft: 50 }}>
						<img alt="" src={require("./images/vector/team.svg")} height='170' />
					</div>
				</div>
			</div>
			<Sitemap />
		</div>
	);
}

export default About;
