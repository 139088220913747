import React, { useState, useContext, useEffect } from 'react';
import { Redirect } from 'react-router';
import { useForm } from "react-hook-form";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import './Login.css';
import DB from './DB';
import { Header, BgDefault, Sitemap } from './Components';
import './Components.css'

function Login() {
    const db = useContext(DB)

    const [redirect, setRedirect] = useState();
    const [User, setUser] = useState();
    const [Error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const { register, handleSubmit } = useForm()

    const loginHandler = (d) => {
        setLoading(true)
        db.login(d.email, d.mobile, (user) => {
            if (user) {
                setUser(user);
                setRedirect('/' + (d.email.split('@')[1] === 'nextup.co.in' ? 'admin-console' : 'dashboard'))
            } else {
                setLoading(false)
            }
        }, (error) => {
            setError(error.message)
            setLoading(false)
            console.log(error.code);
        })
    }

    useEffect(() => {
        db.loginFetch((user) => {
            if (user) {
                setUser(user);
                setRedirect('/' + (user.email.split('@')[1] === 'nextup.co.in' ? 'admin-console' : 'dashboard'))
            } else {
                setLoading(false)
            }
        })
    }, [db])


    if (redirect) return <Redirect push to={{ pathname: redirect, state: User }} />
    return (
        <div style={{ overflow: 'hidden' }}>
            <BgDefault />
            <Header />
            <h3 className='loginTitle'>Next Up&reg; Program Dashboard </h3>
            <div className='loginDescr'>If you've registered for a Next Up&reg; workshop or certified program you can sign in here to access program resources, download your documents and manage your courses.</div>

            <Form className='login-container' onSubmit={handleSubmit(loginHandler)}>
                <div className='loginGradient' >
                    <img src={require("./images/vector/login2.svg")} height={170} alt="" />
                </div>

                <div className='loginFields'>
                    <h3 style={{ marginTop: 30, width: '100%' }}>Login</h3>
                    <br />
                    <Form.Control type="email" name='email' placeholder='Email' className="textfield field" ref={register({ required: true })} />
                    <Form.Control type="password" name='mobile' placeholder='Mobile' className="textfield field" ref={register({ required: true })} />
                    <Button type="submit" className='submitLogin'>
                        {
                            loading ? <Spinner
                                as="span"
                                animation="border"
                                role="status"
                                size="sm"
                            /> : <div>LOGIN</div>
                        }
                    </Button>
                    <br />
                    {Error ? <p className="login-alert">{Error}</p> : <br />}
                    <div className='join'>
                        Don't have an account? Join a Next Up&reg; certified program now.
                    </div>
                </div>

            </Form>
            {/* </div>  */}
            <br />
            <Sitemap />
        </div>
    );
}

export default Login;