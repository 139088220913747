import React, { useState, useEffect, useContext } from "react";
import { Header } from './Components';
import './Components.css'
import { Redirect } from 'react-router';
import QRCode from "qrcode.react";
import DB from './DB';
import moment from 'moment';
import { Fragment } from "react";

function Reciept() {
	const db = useContext(DB)

    const [redirect, setRedirect] = useState();
    const [params, setParams] = useState();

    useEffect(() => {
        var url = new URL(window.location.href);
        let _params = {
            status: url.searchParams.get("r"),
            eventId: url.searchParams.get("e"),
            txnId: url.searchParams.get("t"),
            amount: url.searchParams.get("a")
        }
        setParams(_params)
        db.updateTxnStatus(_params)
    }, [db])

    if (redirect) return <Redirect push to={{ pathname: redirect }} />

    return (
        <div className='spacer layer1'>
            <Header />

            {
                params && <Fragment>
                    <div className='receipt'>
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 874 1883" height="630px" class="ticket ">
                            <title>ticket</title>
                            <path className='ticket' d="M876.8,1225.1V71.6a71.6,71.6,0,0,1-8,.5,63,63,0,0,1-63-63,66.3,66.3,0,0,1,.7-10H804l-17.6,14L768.9-.9H763l-17.6,14L727.9-.9H722l-17.6,14L686.9-.9H682l-17.6,14L646.9-.9H641l-17.6,14L605.9-.9H601l-17.6,14L565.9-.9H559l-17.6,14L523.9-.9H519l-17.6,14L483.9-.9H478l-17.6,14L442.9-.9H438l-17.6,14L402.9-.9H395l-17.6,14L359.9-.9H355l-17.6,14L319.9-.9H314l-17.6,14L278.9-.9H274l-17.6,14L238.9-.9H232l-17.6,14L196.9-.9H192l-17.6,14L156.9-.9H151l-17.6,14L115.9-.9H111l-17.6,14L75.9-.9H73a66.3,66.3,0,0,1,.8,10,63.1,63.1,0,0,1-63,63,68.7,68.7,0,0,1-8-.5V1225.1a63,63,0,0,1,62.8,59h49.2v7H65.7a63.1,63.1,0,0,1-62.9,60v468a63.1,63.1,0,0,1,63,63h7.8l18.8-14.9,18.8,14.9h2.4l18.8-14.9,18.8,14.9h3.4l18.8-14.9,18.8,14.9h2.4l18.8-14.9,18.8,14.9h4.4l18.8-14.9,18.8,14.9h2.4l18.8-14.9,18.8,14.9h3.4l18.8-14.9,18.8,14.9h2.4l18.8-14.9,18.8,14.9h5.4l18.8-14.9,18.8,14.9h2.4l18.8-14.9,18.8,14.9h3.4l18.8-14.9,18.8,14.9h2.4l18.8-14.9,18.8,14.9h4.4l18.8-14.9,18.8,14.9h2.4l18.8-14.9,18.8,14.9h3.4l18.8-14.9,18.8,14.9h2.4l18.8-14.9,18.8,14.9h3.4l18.8-14.9,18.8,14.9h3.4l18.8-14.9,18.8,14.9h9.6a63,63,0,0,1,63-63v-468a63.1,63.1,0,0,1-63-60h-49v-7h49.1A63,63,0,0,1,876.8,1225.1Zm-662,66h-50v-7h50Zm100,0h-50v-7h50Zm100,0h-50v-7h50Zm100,0h-50v-7h50Zm100,0h-50v-7h50Zm100,0h-50v-7h50Z"
                                transform="translate(-2.8 0.9)" />
                        </svg>

                        <div className='receiptText'>
                            <img src={require('./images/main/logo.png')} className='receiptLogo' alt='' /><br />
                            <img src={require(`./images/${params.eventId.includes('CAR') ? 'ar' : 'ml'}/logo.png`)} className='courseLogo' alt='' />
                            <div style={{ marginTop: -35 }}><b>{params.status === 's' ? "REGISTERED" : "FAILED"}</b></div>
                            <img src={require(`./images/reciept/${params.status === 's' ? "tick2.gif" : "cross.png"}`)} className='tickLogo' alt='' /><br />
                            <span>
                                DATE: {moment().format('DD/MM/YYYY')}<br />
                                AMOUNT: ₹ {params.amount}<br />
                            </span>
                            <br /> <br />
                            <QRCode value={params.txnId} className='qrcode' size={105} /><br />{params.txnId}
                        </div>
                    </div>

                    <div className='receiptBlueBox'>
                        <img src={require('./images/vector/pay2.svg')} className='payVector' alt='' />
                        <h3>Payment {params.status === 's' ? "Successful" : "Failed"}</h3><br />
                        <button className="registerbtn" onClick={() => setRedirect(`${params.status === 's' ? "/login" : "/"}`)} style={{ width: '50%' }}>
                            Go To {params.status === 's' ? "Dashboard" : "Home"}
                        </button>
                    </div>
                </Fragment>
            }

        </div>
    )
}
export default Reciept;